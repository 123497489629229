/* eslint-disable max-len,no-unused-expressions,no-invalid-this,object-curly-newline,implicit-arrow-linebreak */
/* global G */
import { asyncPipeSpread, def, deleteKey, setKey } from '@gaia/util'
import _setAppHistory from '@platform/adapter/router/effect/setAppHistory'
import _resetAppHistory from '@platform/adapter/router/effect/resetAppHistory'
import _setAppRoute from '@platform/adapter/router/effect/setAppRoute'
import _setAppCache from '@platform/adapter/router/effect/setAppCache'
import _restoreCachedApp from '@platform/adapter/router/effect/restoreCachedApp'
import _forceAppModal from '@platform/adapter/router/effect/forceAppModal'
import _getDefaultRoute from '@platform/adapter/router/effect/getDefaultRoute'
import toAction from '@platform/adapter/router/api/toAction'
import toModule from '@platform/adapter/router/api/toModule'
import toModuleAction from '@platform/adapter/router/api/toModuleAction'
import toModalModuleAction from '@platform/adapter/router/api/toModalModuleAction'
import toForm from '@platform/adapter/router/api/toForm'
import back from '@platform/adapter/router/api/back'
import newTab from '@platform/adapter/router/api/newTab'
import error from '@platform/adapter/router/api/error'

/**
 * Router API
 *
 * @memberOf Gaia.Adapter#
 * @namespace Router
 *
 * @type {Object}
 * @property {function} action - async {@link seqModuleAction} with app history, app route
 * @property {function} module - async {@link seqModuleInit}
 * @property {function} moduleAction - async {@link seqModuleInit}, {@link seqModuleAction} with
 *   app history, app route
 * @property {function} modal - async {@link seqModuleInit}, {@link seqModuleAction} with forced
 *   modal flag
 * @property {function} modalModule - async {@link seqModuleInit} with app cache set
 * @property {function} modalAction - async {@link seqModuleAction} with app history, forced modal
 *   flag, app route
 * @property {function} restore - to use after modal* methods, restores cached module and action
 * @property {Gaia.Route.Spec} defaultRoute - default route object, found in app configuration
 * @property {function} context - setter/getter for the application context
 * @property {function} defaultContext - sets the application's configured default context
 * @property {function} defaultContextName - returns the name of the application's default context
 * @property {function} back - fire and forget, goes back in history or restores cached module
 * @property {function} confirm - enables the discard changes functionality for an action when
 *   it is called as an action hook
 * @property {function} newTab - attempts to open the passed route in a new browser's tab
 * @property {function} go - removes {@param count} entries from the start of the history stack and
 *   redirects to the next one
 *
 * @param {Gaia.Web.Application} app - web application reference
 * @return {Object} api - router api
 */
const api = app => Object.create({}, {
  path: {
    value: () => {
      const { location: { hash } } = window
      const length = hash.indexOf('?') !== -1 ? hash.indexOf('?') : hash.length
      const parts = hash.substring(2, length).split('/')
      const [context, module, action, key, token] = parts
      return { context, module, action, key, token }
    },
    iterable: true,
    enumerable: true,
  },
  action: {
    value: asyncPipeSpread(
      _setAppHistory(app),
      toAction(app),
      _resetAppHistory(app),
      _setAppRoute(app),
    ),
    iterable: true,
    enumerable: true,
  },
  module: {
    value: toModule(app),
    iterable: true,
    enumerable: true,
  },
  moduleAction: {
    value: asyncPipeSpread(
      _setAppHistory(app),
      toModuleAction(app),
      _resetAppHistory(app),
      _setAppRoute(app),
    ),
    iterable: true,
    enumerable: true,
  },
  modal: {
    value: asyncPipeSpread(
      _setAppHistory(app),
      _setAppCache(app),
      toModalModuleAction(app),
      _resetAppHistory(app),
      _setAppRoute(app), // todo: why would i need route, if modal is not bookmarkable? wizard??
    ),
    iterable: true,
    enumerable: true,
  },
  modalModule: {
    value: asyncPipeSpread(
      _setAppCache(app),
      toModule(app),
    ),
    iterable: true,
    enumerable: true,
  },
  modalAction: {
    value: asyncPipeSpread(
      _setAppHistory(app),
      _setAppCache(app),
      async (route, ...args) => {
        _forceAppModal(app)(route)
        await toAction(app)(route[G.ACTION], ...args)
        return args
      },
      _resetAppHistory(app),
      _setAppRoute(app),
    ),
    iterable: true,
    enumerable: true,
  },
  restore: {
    value: _restoreCachedApp(app),
    iterable: true,
    enumerable: true,
  },
  setRestorePoint: {
    value: route => setKey(route, G.ORIGIN, app[G.STATE]),
    iterable: false,
    enumerable: true,
  },
  restorePoint: {
    value: () => {
      const route = app[G.STATE][G.ORIGIN] || _getDefaultRoute(app)
      app[G.STATE][G.ORIGIN] && deleteKey(G.ORIGIN, app[G.STATE])
      return route
    },
    iterable: false,
    enumerable: true,
  },
  defaultRoute: {
    value: () => _getDefaultRoute(app),
    iterable: false,
    enumerable: true,
  },
  currentRoute: {
    value: () => app[G.STATE][G.ROUTE],
    iterable: true,
    enumerable: true,
  },
  context: {
    value: newContext => (def(newContext)
      ? setKey(newContext, G.CONTEXT, app[G.SESSION][G.STATE])
      : app[G.SESSION][G.STATE][G.CONTEXT]),
    enumerable: true,
  },
  defaultContext: {
    value: () => setKey(app[G.CONFIGURATION].defaults.context, G.CONTEXT, app[G.SESSION][G.STATE]),
    iterable: true,
    enumerable: true,
  },
  defaultContextName: {
    value: () => app[G.CONFIGURATION].defaults.context,
    iterable: true,
    enumerable: true,
  },
  back: {
    value: back(app),
    iterable: true,
    enumerable: true,
  },
  confirm: {
    value: toForm(app),
    iterable: true,
    enumerable: true,
  },
  newTab: {
    value: newTab(app),
    iterable: true,
    enumerable: true,
  },
  go: {
    value: async (count) => {
      const sessionState = app[G.SESSION][G.STATE]
      sessionState[G.PREV] = sessionState[G.PREV].slice(0, count)
      await window.history.back()
    },
    enumerable: true,
  },
  error: {
    value: error(app),
    iterable: true,
    enumerable: true,
  },
})

export default api
