/* eslint-disable no-unused-expressions */
/* global G */
import { setKey } from 'lib/util'
import { isRemote } from '@platform/adapter/attachment/helper'

/**
 * Moves a file from the adapter's (G.CACHE) remote files list to the adapter's (G.DELETE) list of
 * remote files to be deleted.
 *
 * @param {Gaia.Adapter.Attachment.Group} group an Attachment adapter's list
 * @param {File} item                           a File object
 */
const markDeleteRemote = (group, item) => {
  const files = group[G.CACHE]
  const itemPos = files.indexOf(item)
  files.splice(itemPos, 1)
  setKey(files, G.CACHE, group)
  const markedFiles = group[G.DELETE]
  markedFiles.push(item)
  setKey(markedFiles, G.DELETE, group)
}

/**
 * Removes a file from the adapter's (G.DATA) local files list.
 *
 * @param {Gaia.Adapter.Attachment.Group} group an Attachment adapter's group
 * @param {File} item                           a File object
 */
const removeLocal = (group, item) => {
  const files = group[G.DATA]
  const itemPos = files.indexOf(item)
  files.splice(itemPos, 1)

  // Resetting {@code warned} property
  const groupFilesize = files.reduce((acc, key) => acc + key.size, 0)
  const warningSize = group?.[G.STATE]?.[G.FILTER]?.warningSize
  if (warningSize && groupFilesize < warningSize) {
    setKey(false, 'warned', group[G.STATE])
  }

  setKey(files, G.DATA, group)
}

/**
 * Attempts to remove a file from the adapter's (G.DATA) local ones. If the file is not a local one,
 * it is moved from the adapter's (G.CACHE) list of remote files to the adapter's (G.DELETE) list of
 * remote files to be deleted. Dispatches a DONE event with the current local and remote ones
 * afterwards.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail }) => {
  const { [G.EVENTS]: eventBus, [G.ATTACHMENT]: adapter } = app[G.ADAPTER]

  const item = detail[G.DATA]
  const callback = detail[G.FN]

  const group = adapter[G.GROUP][groupName]
  isRemote(item) ? markDeleteRemote(group, item) : removeLocal(group, item)
  const adapterData = group[G.DATA].concat(group[G.CACHE])
  eventBus.dispatch(
    eventBus.type(G.ATTACHMENT, G.DONE, groupName),
    { [G.DATA]: adapterData, [G.STATE]: group[G.STATE] },
  )

  callback?.(adapterData)
}

export default fn
