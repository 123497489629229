/* global G */

/**
 * Redirect To Error Action
 *
 * Redirects to the error page
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {number} code               the error code
 * @param {string} [key=null]         the key of the document we couldn't find
 * @param {object} response           the server response
 * @returns {Promise<void>}
 */
export default async (obj, { code, key, response }) => {
  const routerAdapter = obj[G.ADAPTER][G.ROUTER]

  await routerAdapter[G.API].error({ code, key, response })
}
