import { useState, useEffect, useRef, useContext, useTransition } from 'react'
import ApplicationContext from '@platform/react/context/application'
import { flushSync } from 'react-dom'

/**
 * useSockets hook
 *
 * Connects to websocket according to {@param events.list} and {@param documentType}. Whenever a
 * websocket message is obtained through the corresponding channel, uses
 * {@param events.onPublication} to alter {@param startData}.
 *
 * The websocket connection is reset whenever {@param deps} change.
 *
 * @param {Object[]} startData            the list to modify according to the websocket messages
 * @param {Object} events                 hook events
 * @param {function} events.list          a function that returns the first part of the socket key
 * @param {function} events.onPublication a function that returns the altered item and a function to
 *                                        alter the list with it
 * @param {string} documentType           the second part of the socket key
 * @param {array} [deps]                  an array of dependencies to reset the connection
 * @returns {{data: object[]}}
 */
const useSockets = (startData, { events, documentType }, deps = []) => {
  const [, startTransition] = useTransition()
  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const socketKey = useRef()
  const [data, setData] = useState(startData)

  useEffect(() => {
    const parentRef = events.list()
    socketKey.current = documentType ? `${parentRef}_${documentType}` : parentRef
    const listener = async (e) => {
      const { item, addItem } = await events.onPublication(e)
      flushSync(() => {
        setData(prevData => (addItem && item ? addItem(prevData, item) : prevData))
      })
    }
    socket.sub(socketKey.current, () => {
      socket.on(socketKey.current, listener)
    })
    return () => socket.unsub(socketKey.current, () => {
      socket.off(socketKey.current, listener)
    })
  }, deps)

  useEffect(() => {
    startTransition(() => {
      setData(startData)
    })
  }, [startData])

  return { data }
}

export default useSockets
