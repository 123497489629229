/* eslint-disable no-nested-ternary */
import rest from '@platform/adapter/http/rest'
import middleware, { defaultMiddleware } from '@platform/adapter/http/middleware'
import { isFn } from 'lib/util'

const {
  post, put, get, patch, del,
} = rest

/**
 * @typedef {array|function(Object):array} MiddlewareOptions
 * @description An array or a callback function that gets passed the {@link middleware} object and
 *              must return an array. In both cases, the array is expected to contain the
 *              middlewares to be used for a specific request.
 *
 * @typedef {'dialog'|'action'} onErrorOptions
 * @description A string, either `dialog` or `action`, denoting what should happen in case
 *              an error appears. `dialog` is the default option, which will lead the adapter
 *              to show an error dialog (in case we have one for the provided error code).
 *              If `action` is set, the adapter will call the {@link Gaia.Adapter.Router}'s
 *              `error` function and pass it the error provided.
 */

/**
 * @type {{middleware: MiddlewareOptions, onError: onErrorOptions}} middleware
 */
const defaultOptions = {
  middleware: defaultMiddleware,
  onError: 'dialog',
}

/**
 * Intercepts {@param methods} by building a Middleware Pattern call chain with their provided
 * {@code options.middleware} parameter.
 *
 * The {@code options.middleware} parameter accepts a callback function with {@link middleware} as
 * its first parameter. The callback must then pick some of them and return an array containing
 * those to be used for that specific call instead of the ones in {@link defaultOptions}.
 *
 * @example
 *  // pick specific middleware for a call
 *  const options = { middleware: ({ persistence, error }) => [persistence, error] }
 *  const result = await app[G.ADAPTER][G.HTTP][G.API].put({ url, params }, options)
 *  // exclude specific middleware from a call
 *  // (caution: the passed object is {@link middleware} and not {@link defaultMiddleware})
 *  const options = { middleware: ({ loader, ...rest }) => Object.values(rest) }
 *  const result = await app[G.ADAPTER][G.HTTP][G.API].put({ url, params }, options)
 *
 * @param {Gaia.Web.Application} obj                    the Web platform Application
 * @param {Object} methods                              the HTTP methods to intercept
 * @return {Object}
 */
const init = (obj, methods) => Object.keys(methods).reduce((acc, method) => {
  /**
   * Performs a request with HTTP Method {@link method}.
   *
   * @param {Object} args                               request's parameters
   * @param {string} args.url                           partial request's url
   * @param {Object|array} args.params                  request body's content
   * @param {Object} args.headers                       request headers
   * @param {AbortSignal} args.signal                   request's abort signal
   * @param {Object} options                            additional request's options
   * @param {MiddlewareOptions} options.middleware      the middlewares to be used for the request
   * @param {onErrorOptions} [options.onError='dialog'] what should happen if an error occurs
   * @return {Promise<any>}
   */
  acc[method] = async (args, options = defaultOptions) => {
    // if `options.middleware` is declared for the current request, use it. If not, use
    // `defaultOptions.middleware`. We can pass the options for a request like so:
    // `{onError: 'action'}`, in which case the `middleware` prop is not defined, and we
    // have to fall back to the default middleware.
    const finalOptions = { ...defaultOptions, ...options }
    const chain = isFn(finalOptions.middleware)
      ? finalOptions.middleware(middleware)
      : finalOptions.middleware
    const callChain = chain.reduce((prev, fn) => fn(obj, prev), methods[method])

    return await callChain({
      ...args,
      onError: finalOptions?.onError,
      method: method.toUpperCase(),
    })
  }
  return acc
}, {})

/**
 * Http Adapter API
 *
 * Exposes intercepted {@link post}, {@link put}, {@link get}, {@link patch} and {@link delete}
 * methods to perform HTTP calls.
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.API
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @returns {Object}                  the Http Adapter API
 */
export default obj => init(obj, {
  get,
  post,
  put,
  patch,
  delete: del,
})
