/* eslint-disable no-undefined,function-paren-newline,implicit-arrow-linebreak */
/* global G */

/**
 * Adds a message to the adapter's G.DATA list of messages and dispatches a G.DONE event.
 * It does not attempt to create the message on the server. This is only used for
 * adding an already existing message to the adapter's G.DATA list of messages.
 * If the message already exists in the set, it doesn't do anything.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const adapter = app[G.ADAPTER][G.MESSAGE]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapterData = adapter[G.DATA]
  const item = detail[G.DATA]

  const localMessage = adapterData.find(message => message.key === item.key || message.id === item.key)

  if (localMessage) {
    localMessage._rev = item._rev
    localMessage.value = item.value
    localMessage.refs = item.refs
  } else {
    adapterData.push(item)
  }

  eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), { [G.DATA]: adapterData })
}

export default fn
