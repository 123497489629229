/* eslint-disable no-unused-vars */
/* global React */
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { useStyles } from '@platform/react/hook'
import { PlatformEvent } from 'lib/util'

const raf = requestAnimationFrame || setImmediate || (c => setTimeout(c, 0))

const styles = () => ({
  container: {
    position: 'relative',
    '&.grabbed': {
      OUserSelect: 'none',
      MsUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      userSelect: 'none',
    },
    '&:hover > .bar, &:active > .bar': {
      opacity: 1,
    },
    '& > .hidden': {
      display: 'none',
    },
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    boxSizing: 'border-box',
    '&.rtl': {
      right: 'auto',
    },
    '&.no-scroll': {
      width: '100%',
      overflow: 'hidden',
    },
  },
  bar: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.1)',
    width: 9,
    borderRadius: 4,
    top: 0,
    zIndex: 2,
    opacity: 0,
    transition: 'opacity 0.25s linear, background 0.10s linear',
    right: '0!important',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
    '&:active': {
      background: 'rgba(0, 0, 0, 0.15)',
    },
  },
})

/**
 * Bar Component.
 *
 * Custom implementation of a vertical scrollbar element that makes use of the native scrolling
 * functionality.
 *
 * The element observes changes in content size and only displays and activates the scroll bar
 * element and its listeners if the content is higher than the container.
 *
 */
const Bar = forwardRef(({ containerRef, contentRef, observedRef, ...props }, ref) => {
  const { children, onScroll, className, direction, bottom } = props
  const [scrollRatio, setScrollRatio] = useState(1)
  const [shouldScroll, setShouldScroll] = useState(bottom)
  const [updateKey, forceUpdate] = useState(0)
  let observer
  let lastPageY

  /**
   * Handles mouse drag events.
   *
   * @param {MouseEvent} event
   */
  const handleDrag = useCallback((event) => {
    const content = contentRef.current
    const delta = event.pageY - lastPageY
    lastPageY = event.pageY

    raf(() => {
      content.scrollTop += delta / scrollRatio
    })
  }, [lastPageY, scrollRatio])

  /**
   * Instructs the container to scroll to the bottom whenever
   * `bottom` changes from the outside
   */
  useEffect(() => {
    bottom && setShouldScroll(true)
  }, [bottom])

  /**
   * Stops listening for mouse events on the scroll bar element by removing {@link stopDrag} and
   * {@link handleDrag} from the document's event listeners.
   */
  const stopDrag = useCallback(() => {
    const container = containerRef.current
    container.classList.remove('grabbed')
    document.removeEventListener('mousemove', handleDrag)
    document.removeEventListener('mouseup', stopDrag)
  }, [handleDrag])

  /**
   * Starts listening for mouse events on the element by registering {@link stopDrag} and
   * {@link handleDrag} as document's event listeners.
   *
   * @param event
   * @returns {boolean}
   */
  const startDrag = useCallback((event) => {
    const container = containerRef.current
    lastPageY = event.pageY
    container.classList.add('grabbed')
    document.addEventListener('mousemove', handleDrag)
    document.addEventListener('mouseup', stopDrag)
    return false
  }, [handleDrag])

  /**
   * Renders the scrollbar.
   */
  const update = useCallback(() => {
    const content = contentRef.current
    const container = containerRef.current
    const bar = ref.current

    const totalHeight = content.scrollHeight

    const isRtl = direction === 'rtl'
    const right = isRtl
      ? container.clientWidth - bar.clientWidth + 18
      : (container.clientWidth - bar.clientWidth) * -1

    setShouldScroll((prevState) => {
      prevState && (content.scrollTop = content.scrollHeight)
      return false
    })

    raf(() => {
      if (scrollRatio < 1) {
        onScroll?.(new PlatformEvent('scroll', { value: content.scrollTop }))
        bar.style.cssText = `height: ${Math.max(scrollRatio * 100, 10)}%; 
        top: ${(content.scrollTop / totalHeight) * 100}%;
        right: ${right}px;`
      }
    })
  }, [onScroll, scrollRatio, shouldScroll])

  /**
   * Calculates {@link scrollRatio} by dividing the content's clientHeight by its scrollHeight.
   */
  const toggle = useCallback(() => {
    const content = contentRef.current

    if (content) {
      const totalHeight = content.scrollHeight
      const ownHeight = content.clientHeight
      setScrollRatio(ownHeight / totalHeight)
      // event if scrollRatio didn't change, we must still trigger the useEffect below
      forceUpdate(i => i + 1)
    }
  }, [])

  /**
   * Toggles rendering the scrollbar and registering its drag listeners only if the current height
   * of the content is greater than the height of the container (i.e. if scrollRatio < 1).
   */
  useEffect(() => {
    const content = contentRef.current
    const bar = ref.current

    if (content && bar) {
      raf(() => {
        if (scrollRatio >= 1) {
          bar.classList.add('hidden')
          content.classList.add('no-scroll')
          content.removeEventListener('scroll', update)
          content.removeEventListener('mouseenter', update)
          bar.removeEventListener('mousedown', startDrag)
        } else {
          bar.classList.remove('hidden')
          content.classList.remove('no-scroll')
          content.addEventListener('scroll', update)
          content.addEventListener('mouseenter', update)
          bar.addEventListener('mousedown', startDrag)
          update()
        }
      })
    }
  }, [update, startDrag, scrollRatio, updateKey])

  /**
   * Initializes the Bar.
   */
  useEffect(() => {
    const content = contentRef.current
    const bar = ref.current
    const observed = observedRef.current
    observer = new ResizeObserver(toggle)
    observer.observe(observed)
    toggle()
    return () => {
      observer.disconnect()
      bar.removeEventListener('mousedown', startDrag)
      content.removeEventListener('scroll', update)
      content.removeEventListener('mouseenter', update)
      content.classList.add('no-scroll')
      bar.classList.add('hidden')
    }
  }, [children, update, startDrag])

  return (
    <div
      ref={ref}
      className={`${className} bar`}
    />
  )
})

/**
 * Scroll HOC
 *
 * Replaces the {@param Component}'s native vertical scrollbar element with a custom implementation
 * in JavaScript that respects the native scroll functionality. Intended to unify the way the
 * scrollbar looks and works in all browsers.
 *
 * @param {React.Component} Component
 * @return {function(*, *)}
 * @constructor
 */
const ScrollHOC = Component => forwardRef(({ children, className = '', ...props }, ref) => {
  const { scrollProps = {}, classes: rootClasses = {}, ...rootProps } = props
  const { enable: enableScroll = true, onScroll, position, bottom } = scrollProps
  const { root: rootClass = '', content: contentClass = '' } = rootClasses
  const [direction, setDirection] = useState('ltr')
  const classes = useStyles(styles)()

  const containerRef = ref || useRef(null)
  const contentRef = useRef(null)
  const barRef = useRef(null)
  const observedRef = useRef(null)

  const containerClassName = `${classes.container} ${className} ${rootClass}`
  const contentClassName = `${contentClass} ${direction}`

  useEffect(() => {
    if (enableScroll) {
      const content = contentRef.current
      const observed = observedRef.current
      if (position?.y >= 0) {
        observed.style.minHeight = `${position.y + content.clientHeight}px`
        content.scrollTop = position.y
      } else {
        delete observed.style.minHeight
        content.scrollTop = 0
      }
    }
  }, [position])

  useEffect(() => {
    const element = containerRef.current
    const css = getComputedStyle(element)

    setDirection(css.direction === 'rtl' ? 'rtl' : 'ltr')
  }, [enableScroll])

  return (
    <Component
      {...rootProps}
      ref={containerRef}
      className={containerClassName}
    >
      {enableScroll ? (
        <>
          <div
            key={'wrapper'}
            className={classes.wrapper}
          >
            <Grid
              key={'content'}
              ref={contentRef}
              container
              className={`${classes.content} no-scroll`}
            >
              <Grid
                ref={observedRef}
                container
                className={contentClassName}
              >
                {children}
              </Grid>
            </Grid>
          </div>
          <Bar
            key={'bar'}
            ref={barRef}
            containerRef={containerRef}
            contentRef={contentRef}
            observedRef={observedRef}
            children={children}
            onScroll={onScroll}
            className={`${classes.bar} hidden`}
            direction={direction}
            bottom={bottom}
          />
        </>
      ) : children}
    </Component>
  )
})

export default ScrollHOC
