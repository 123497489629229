/* global G */

import { useContext, useRef, useState } from 'react'
import ApplicationContext from '@platform/react/context/application'
import useEventHandler from '@platform/react/hook/useEventHandler'
import useEventCallback from '@platform/react/hook/useEventCallback'

/**
 * Listens to G.DONE events and keeps its state up to date by filling it with the new messages.
 *
 * @returns {Object} state  the hook state
 */
const useMessageAPI = () => {
  const { eventBus } = useContext(ApplicationContext)

  const [state, setState] = useState({
    messages: [],
  })

  const _msgEventName = useRef(eventBus.type(G.MESSAGE, G.DONE))
  const _msgEventHandler = useEventCallback(({ detail }) => {
    const { [G.DATA]: messages } = detail

    messages.forEach((message) => {
      const messageAttachments = message.value?.attachments || []
      messageAttachments.forEach((att) => {
        // Only true for local attachments
        if (att._rev === null) {
          // If we have a local attachment, meaning ones that the user just uploaded but hasn't
          // refreshed the page, their blob url will be broken because as soon as we close the
          // upload drawer we revoke their URLs in the useEffect cleanup function. So for those
          // attachments, dispatch the event again so that the components that show them get a
          // new blob url.
          eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.READ), { [G.DATA]: att })
        }
      })
    })

    setState({ messages })
  })
  useEventHandler(eventBus, _msgEventName.current, _msgEventHandler)

  return { state }
}

export default useMessageAPI
