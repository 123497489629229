/* eslint-disable import/prefer-default-export,object-curly-newline */
/* global G */

/**
 * Returns the parts of the URL to be used to generate the messages URL. For that, it makes use
 * of the current model's api values, that can be overridden with the optional {@code api} object.
 *
 * @param {Gaia.Web.Application} app    the Platform Web Application
 * @param {Object} [props]              an optional object, in case we want to override the current
 *                                      model values to form the URL
 * @returns {{ref, host, api, version}} the URL parts
 */
const getUrlParts = (app, props = {}) => {
  const appState = app[G.SESSION][G.STATE]
  const host = appState[G.HTTP]
  const model = appState[G.MODULE][G.MODEL]
  const version = props.version || model[G.PROPS].version
  const urlParts = { host, version, ...props.key && { key: props.key } }
  const part = Object.keys(urlParts).find(i => !urlParts[i])
  // eslint-disable-next-line no-unused-expressions
  part && throw ReferenceError(`${part} is required`)
  return urlParts
}

/**
 * Returns the messages url.
 *
 * @param {Gaia.Web.Application} app    the Platform Web Application
 * @param {Object} [props]
 * @returns {string}
 */
export const getUrl = (app, props) => {
  const { host, version, key = '' } = getUrlParts(app, props)
  return `${host}/api/v${version}/message/${key}`
}

/**
 * Returns the draft url.
 *
 * @param {Gaia.Web.Application} app    the Platform Web Application
 * @param {Object} [props]
 * @returns {`${string}/?draft=true`}
 */
export const getDraftUrl = (app, props) => `${getUrl(app, props)}/?draft=true`

/**
 * Disables the loader middleware
 *
 * @param {Function} loader   loader middleware
 * @param {object} middleware other middlewares
 * @returns {unknown[]}
 */
export const noLoaderMiddleware = ({ loader, ...middleware }) => Object.values(middleware)
