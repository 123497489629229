/* eslint-disable no-nested-ternary */
/**
 * List of platform specific errors we know of.
 * @type {string[]}
 */
const platformErrors = [
  'HttpError', // Handled errors by the http adapter
  'StateError', // If `module[G.STATE][G.ERROR]` is truthy (validation errors)
]

/**
 * Checks whether `e` is a {@link PlatformError}.
 *
 * @param {Error} e     the underlying error
 * @returns {boolean}
 */
export const isPlatformError = e => e.name === 'PlatformError'

/**
 * Returns the underlying error in case `e` is of type {@link PlatformError}.
 * If not, it just returns `e`.
 *
 * @param {PlatformError|Error} e   the error
 * @returns {*}
 */
export const getError = e => (isPlatformError(e) ? e.error : e)

/**
 * Platform Error
 *
 * Creates a `PlatformError` from the underlying `error`.
 *
 * @param {Gaia.AppModule.Spec} obj app module composition
 * @param {string} descriptor       descriptor of the instance
 * @param {Error} error             underlying error
 * @constructor
 */
function PlatformError(obj, descriptor, error) {
  this.message = `${obj._name} ${descriptor} - ${error.message}`
  this.error = error
}
PlatformError.prototype = Object.create(Error.prototype)
PlatformError.prototype.constructor = PlatformError
PlatformError.prototype.name = 'PlatformError'

/**
 * PlatformError Constructor
 *
 * If `error` is not one of the {@link platformErrors} we know of, we will simply
 * return a normal {@link Error}. This way, we can make sure that wherever we catch
 * the error, we can use {@link isPlatformError} to know what we need to do.
 *
 * @param {Gaia.AppModule.Spec} obj app module composition
 * @param {string} descriptor       descriptor of the instance
 * @param {Error} error             underlying error
 * @returns {PlatformError|Error}
 */
export default (obj, descriptor, error) => (isPlatformError(error)
  ? error
  : platformErrors.includes(error.name)
    ? new PlatformError(obj, descriptor, error)
    : new Error(`${obj._name} ${descriptor} - ${error.message}`))
