/* global G */

/**
 * Forbidden error dialog
 *
 * Displays an error informing the user that they are not allowed to perform an operation.
 *
 * To be displayed when a response with code 403 is received from the server.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.default.title',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.default.title',
        defaultValue: 'Permission denied',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.default.text',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.default.text',
        defaultValue: 'Your account does not have sufficient permissions for the transaction.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })
}
