import minLength from './minLength'

/**
 * Validator - Not Empty
 *
 * @param {string} value - value to validate
 * @param {Object} options - validator options
 * @return {string} value - validated value
 */
export default (value, options) => {
  try {
    return minLength(value, { ...options, min: 1 })
  } catch (e) {
    const { error } = options
    throw TypeError(error)
  }
}
