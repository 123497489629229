import HttpError from '@platform/adapter/http/error'
import { API_URL } from 'lib/util/environment'

const status = {
  400: 'Invalid data sent',
  401: 'Invalid credentials',
  403: 'You have insufficient permissions',
  404: 'The given URL does not exist, contact Support',
  409: 'Data state conflict',
  412: 'Confirmation error',
  428: 'Confirmation required',
  429: 'Too many requests. Please, try again later',
  500: 'Internal server error',
  503: 'Service unavailable',
  504: 'API Server is down',
}

/**
 * Unknown Errors Mapping
 *
 * A mapping between a type we know of (like `FETCH_FAILED`) and a string that maps to the
 * respective type. This is used to map an unknown error, that is an error that was not
 * thrown by our rest API and only consists of a `message` and a `stack`, to an error the
 * application is aware of and can be further processes.
 *
 * Mapping is performed by looking at the error's `message` and comparing
 * it with the value of each key/value pair in this object.
 * @type {{FETCH_FAILED: string}}
 */
const unknownErrorsMapping = {
  FETCH_FAILED: 'Failed to fetch',
}

const _headers = (headers = {}) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...headers,
})

/**
 * Url Build Helper
 *
 * uses runtime window.location.origin to build a url to use with REST API call
 *
 * @param {string} url - url, beginning with /, ie /api/:version/:endPoint
 * @return {URL || RequestInfo} url - URL
 * @private
 */
// const _url = url => new URL(`${DEV_SERVER_PROTOCOL}://${DEV_ENV_URL}:${DEV_SERVER_PORT}${url}`)
const _url = url => new URL(url, API_URL)
// const _url = url => new URL('http://www.example.com')

/**
 * Error Thrower
 *
 * Throws HttpError error with given message
 *
 * @param {*} message       a string, or an object, containing error information
 * @param {number} code     a numeric status identifier
 * @param {any} [response]  response data
 * @param {string} [key]    key of the document that wasn't found in case of 404
 * @throws HttpError
 * @private
 */
const _error = (message, code, response, key) => throw new HttpError(message, code, response, key)

/**
 * Key Getter
 *
 * Gets the key of the document that is contained in `path`.
 * Will return the key or `null`.
 *
 * @param {string} path
 * @returns {string|null}
 * @private
 */
const _key = (path) => {
  const url = _url(path)
  return url?.pathname?.split?.('/')?.pop() || null
}

/**
 * Invalid Status Code Handler.
 *
 * global Symbol(ERROR) API -> throw, _getCode, _getMessage
 * environment dependent error messages map
 *
 * @param {Response} result the server response
 * @throws on invalid status code
 * @return {Promise<number>} status
 * @private
 */
const _status = async (result) => {
  const code = result.status
  const error = status[code]
  const key = _key(result.url)
  error && _error(error, code, await result.json(), key)
  return code
}

/**
 * Result Data resolver
 *
 * converts incoming request result to json,
 * adds symbol STATE to payload, containing result.status code, ie 200, 404, 401, etc.
 *
 * @param {Response} result the server response
 * @return {Promise<{}>}
 * @private
 */
const _data = async (result) => {
  await _status(result)
  return await result.json()
}

/**
 * Error Mapper
 *
 * Maps the incoming `error` to a {@link HttpError} if we can match its type using
 * {@link unknownErrorsMapping}. If `error` is already a {@link HttpError}, or its type could
 * not be match, it'll simply be returned for further escalation.
 *
 * @param {Error} error the thrown error
 * @param {object} args the request's arguments
 * @returns {Error|HttpError}
 */
const _mapError = (error, args) => {
  if (error.name === 'HttpError') return error

  const errorType = Object.keys(unknownErrorsMapping).find(
    type => error.message.includes(unknownErrorsMapping[type]),
  )

  if (errorType) {
    const key = _key(args.url)
    return new HttpError(error.message, errorType, null, key)
  }

  return error
}

export default {
  _headers,
  _url,
  _status,
  _data,
  _key,
  _mapError,
}
