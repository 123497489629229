/**
 * HttpError constructor.
 *
 * Extends the base Error class by adding a code property to it, allowing us to pass the actual HTTP
 * status codes through throw statements.
 *
 * @param {string} message          a short text informing about the error
 * @param {number|string} code      an identifier for the error
 * @param {any} [response]          response data
 * @param {string|null} [key=null]  if it's an 404 error, this is the id of the
 *                                  document that couldn't be found
 */
function HttpError(message, code, response, key = null) {
  this.message = message
  this.code = code
  this.key = key
  this.response = response
}

HttpError.prototype = Object.create(Error.prototype)
HttpError.prototype.constructor = HttpError
HttpError.prototype.name = 'HttpError'

export default HttpError
