/* global SENTRY_DSN, ENV, TENANT */
import { curry } from 'lib/util'
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,
  integrations: [],
  tracesSampleRate: 0.0,
  normalizeDepth: 5,
  initialScope: {
    tenant: TENANT,
  },
  beforeSend(event) {
    const { data = {} } = event.contexts
    if (data['Request Data']?.params?.password) {
      data['Request Data'].params.password = '******'
    }
    return event
  },
})

/**
 * Returns {@code true} if {@param error} should be captured and sent to the Sentry monitoring
 * platform or {@code false} otherwise.
 *
 * @param {number} code an http status code
 * @return {boolean}    whether the error should be captured
 */
const shouldCapture = code => code >= 400 && ![401, 403, 404, 409, 429].includes(code)

/**
 * Sentry middleware handler.
 *
 * Sends a message to the Sentry monitoring platform whenever the server responds with an error.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {function} next             the next middleware function
 * @param {Object} args               the request's arguments
 * @return {Object}                   the response object
 */
export default curry(async (obj, next, args) => {
  let result
  try {
    result = await next(args)
  } catch (error) {
    if (shouldCapture(error.code)) {
      Sentry.withScope((scope) => {
        scope.setContext('data', {
          'Status Code': error.code,
          'Request Data': args,
          'Response Data': error.response,
          'Error Message': error.message,
          Tenant: TENANT,
        })
        Sentry.captureException(error)
      })
    }
    throw error
  }
  return result
})
