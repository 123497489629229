/* global G */
import { useContext, useRef, useState } from 'react'
import ApplicationContext from '@platform/react/context/application'
import useEventHandler from '@platform/react/hook/useEventHandler'
import useEventCallback from '@platform/react/hook/useEventCallback'

/**
 * @typedef {'excludeOnly', 'exclude', 'include', 'only'} timeNotesType
 * - `excludeOnly`: Exclude notes that have the `timeOnly` property set to `true`.
 * This will exclude notes that are solely for time tracking purposes.
 * - `exclude`: Exclude notes that have the `value.spentTime` property set.
 * This will exclude all notes with time tracking information.
 * - `include`: Include notes that have the `value.spentTime` property set.
 * This will include all notes.
 * - `only`: Only use the notes that have the `value.spentTime` property set.
 * This will include only time tracking notes.
 */

/**
 * Listens to G.DONE events and keeps its state up to date by filling it with the new notes.
 *
 * @param {timeNotesType} [timeNotes='excludeOnly']  controls how to handle notes that have a
 *                                                   time set. See {@link timeNotesType}.
 * @returns {Object} state  the hook state
 */
const useNoteAPI = (timeNotes = 'excludeOnly') => {
  const { eventBus } = useContext(ApplicationContext)

  const [state, setState] = useState({
    notes: [],
  })

  const _msgEventName = useRef(eventBus.type(G.NOTE, G.DONE))
  const _msgEventHandler = useEventCallback(({ detail }) => {
    const { [G.DATA]: notes } = detail
    setState({
      notes:
      (timeNotes === 'include' && notes)
      || (timeNotes === 'excludeOnly' && notes.filter(x => !x.value.timeOnly))
      || (timeNotes === 'exclude' && notes.filter(x => !x.value.spentTime))
      || (timeNotes === 'only' && notes.filter(x => x.value.spentTime)),
    })
  })
  useEventHandler(eventBus, _msgEventName.current, _msgEventHandler)

  return { state }
}

export default useNoteAPI
