/* eslint-disable no-param-reassign,no-unused-expressions */
/* global G */
import { asyncpipe, curry, isArr, isStr } from 'lib/util'
import { withDependencyCheck } from 'trait/with'
import sequenceModelMap from '@gaia/sequence/model/api/map'

const descriptor = 'sequence::model::read'

/**
 * Preliminary Check for sequence required attributes.
 *
 * @param obj - model object composition
 * @param component - component object composition
 * @return {*}
 */
const checkDeps = (obj, component) => {
  withDependencyCheck(`${descriptor} model`, [G.CHILDREN, G.CACHE, G.ADAPTER, G.STATE, G.PROPS], obj)
  withDependencyCheck(`${descriptor} model adapter`, [G.HTTP], obj[G.ADAPTER])
  // if obj[G.CACHE] is truthy, obj[G.STATE][G.REF] is not needed/used. Useful to just propagate
  // model's cache to its children
  // withDependencyCheck(`${descriptor} model state`, [G.REF], obj[G.STATE])
  withDependencyCheck(`${descriptor} component`, [G.CHILDREN], component)
  return component
}

/**
 * Model Error Purger
 *
 * Resets ERROR flag in Model's State
 *
 * @param {Gaia.Model.Spec} obj - model composition
 * @param {Gaia.Component} component - component composition
 * @return {*}
 */
const purgeError = (obj, component) => {
  const objState = obj[G.STATE]
  objState[G.ERROR] = null
  return component
}

/**
 * Remote Data Fetch
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @return {Promise<*>}
 */
const readData = async (obj) => {
  // grab data
  const { api, version, read } = obj[G.PROPS]
  // G.REF is set via edit() eventHandler
  const id = obj[G.STATE][G.REF]
  const url = `/api/v${version}/${api}/${id}${read ? `/${read}` : ''}` // /${list}/${view}
  // TODO: remove when no longer used; we should always obtain _rev now
  const params = {
    edit: true,
  }

  const payload = await obj[G.ADAPTER][G.HTTP].get({ url, params }, { onError: 'action' })

  if (payload.error) {
    console.error(payload)
    throw Error(payload)
  }
  return payload
}

const getCacheValue = (initialValue) => {
  let value
  switch (true) {
    // 1:1 ref, ie Organisation<->Address
    case initialValue && isArr(initialValue) && initialValue.length === 1:
      return initialValue[0] // {key, value}
    // todo: 1:n ref, ie Organisation<->Info
    // case isArr(initialValue) && initialValue.length > 1:
    default:
      value = initialValue
  }
  return value
}

/**
 * Clears the cache of {@param obj}'s children, recursively.
 *
 * Used to delete data set by previous models/requests.
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @returns {Gaia.Model.Spec}
 */
const clear = (obj) => {
  try {
    obj[G.CHILDREN] && Object.values(obj[G.CHILDREN]).map((attribute) => {
      attribute[G.CACHE] = null
      return clear(attribute)
    })
  } catch (e) {
    console.error(e)
  }
  return obj
}

/**
 * Model Read Sequence
 *
 * reads data based on model ref and api keys
 * sets cache attribute to incoming data value
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @param {Gaia.Component.Spec} component - component object composition, ie module[G.ACTION]
 * @return {Promise<*>}
*/
const read = async (obj, component) => {
  obj[G.CACHE] = obj[G.CACHE] || await readData(obj)

  const attributeKeys = Object.keys(obj[G.CHILDREN])
  const { value, refs } = obj[G.CACHE]
  attributeKeys.reduce(async (acc, key) => {
    const item = obj[G.CHILDREN][key]
    const cacheValue = (refs && refs[key]) || (value && value[key]) || obj[G.CACHE][key]

    if (item[G.CHILDREN]) {
      item[G.CACHE] = getCacheValue(cacheValue)
      // recursion for sub models || clear children's cache if item's cache cannot be determined
      item[G.CACHE] ? await read(item) : clear(item)
      // todo: refs - missing existing ref, rev..
      const cache = item[G.CACHE]
      item[G.STATE][G.REF] = (cache && (isStr(cache) ? cache : cache.key)) || null
    }
    item[G.CACHE] = cacheValue
    return acc
  }, false)

  // do some lazy propagation, based on visibility
  // identify visible elements
  // set children cache to initial data
  // component[G.CHILDREN] && component[G.CHILDREN]
  //   .reduce(async (acc, ui) => {
  //     try {
  //       const { key } = ui[G.PROPS]
  //       if (attributeKeys.includes(key)) {
  //         const item = obj[G.CHILDREN][key]
  //         const { value, refs } = obj[G.CACHE]
  //         const cacheValue = (value && value[key]) || (refs && refs[key])
  //         item[G.CACHE] = cacheValue
  //         if (item[G.CHILDREN]) {
  //           item[G.CACHE] = getCacheValue(cacheValue)
  //           // recursion for sub models
  //           item[G.CACHE] && await read(item, ui)
  //           // todo: refs - missing existing ref, rev..
  //           item[G.STATE][G.REF] = item[G.CACHE].key
  //         }
  //       }
  //     } catch (err) {
  //       console.warn(descriptor, err.message)
  //     }
  //     return acc
  //   },
  //   [])
  return component
}

const asyncMap = async (obj, component) => await sequenceModelMap(obj)(component)

/**
 * Model Validation Sequence.
 *
 * Validates currently visible data by executing model attribute(child) validators
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @return {function(*=): *}
 */
export default obj => asyncpipe(
  curry(checkDeps)(obj),
  curry(purgeError)(obj),
  curry(read)(obj),
  // todo: move this call outside this sequence,
  //  automation hinders some processes
  curry(asyncMap)(obj),
)
