/* global G */
import { setKey } from 'lib/util'
import seqRead from 'lib/sequence/model/api/read'
import seqReset from '@gaia/sequence/model/api/reset'

const descriptor = Symbol('hook::model::setAttribute').toString()

/**
 * Sets the {@code G.CACHE} and {@code G.STATE[G.REF]} properties of the
 * current {@param model} to the values set in the incoming {@param data}, if
 * they are provided.
 *
 * If {@param model} has any children that are also present in {@param data},
 * it will recursively go through them and call itself for each child.
 *
 * @param {Gaia.Model} model model to mutate
 * @param {Gaia.Model} data incoming model
 */
const setModelData = (model, data) => {
  data[G.CACHE] && setKey(data[G.CACHE], G.CACHE, model)
  data[G.STATE]?.[G.REF] && setKey(data[G.STATE][G.REF], G.REF, model[G.STATE])

  Object.keys(model[G.CHILDREN] || []).forEach((subModelKey) => {
    if (data[G.CHILDREN][subModelKey]) {
      setModelData(model[G.CHILDREN][subModelKey], data[G.CHILDREN][subModelKey])
    }
  })
}

/**
 * Model Set Attribute Hook
 *
 * Sets the current models {@param attribute} sub-model to the incoming data. Can happen in either
 * one of two ways
 * - If {@code key} is present as the first argument, it will set {@code attribute[G.STATE][G.REF]}
 * to it. One can then execute {@link readAttribute} to get the data.
 * - If a model is provided as the first argument that has the same name as the {@param attribute},
 * {@link setModelData} is executed with the given model.
 *
 * @param {string} attribute  attribute to read
 * @return {function(...[*]): *[]}
 */
const modelSetAttribute = attribute => obj => async (...args) => {
  try {
    const [{ key = null, [attribute]: sourceModel } = {}] = args || []
    const targetModel = obj[G.MODEL][G.CHILDREN][attribute]

    if (targetModel && sourceModel) {
      // We have a complete incoming model, no need to refetch it
      // just set the target model to it
      setModelData(targetModel, sourceModel)
    } else if (targetModel && (key || targetModel[G.STATE][G.REF])) {
      // If we don't have an incoming model, we either have an incoming key
      // or an id already present in the target models G.STATE, in either
      // case, we need to reset the current model and refetch it to ensure
      // we have the newest data
      const currentKey = key || targetModel[G.STATE][G.REF]

      seqReset(targetModel)
      setKey(currentKey, G.REF, targetModel[G.STATE])

      await seqRead(obj[G.MODEL][G.CHILDREN][attribute])(obj[G.STATE][G.ACTION][G.COMPONENT])
    }
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }

  return args
}

export default modelSetAttribute
