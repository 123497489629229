/* eslint-disable object-curly-newline */
/* global G */
import createWorkerBody from '@platform/worker/attachment/create'
import { createWorker, getCreateUrl, getDraftUrl, terminate } from '@platform/adapter/attachment/helper'

/**
 * Listener function executed on successful create call.
 *
 * @callback
  * @param {Gaia.Web.Application} app the Platform Web Application
 * @param {string} groupName          the name of the group the file is successfully created for
 * @param {File} item                 a File object
 * @returns {(Object) => void}        a response listener
 */
export const onComplete = (app, groupName, item) => ([payload]) => {
  const adapter = app[G.ADAPTER][G.ATTACHMENT]
  const bus = app[G.ADAPTER][G.EVENTS]

  const group = adapter[G.GROUP][groupName]

  // The group may have been configured to not add the returned attachment again
  if (group && group[G.STATE][G.CACHE]) { // may already have been cleared
    const itemPos = group[G.DATA].indexOf(item)
    const data = group[G.DATA]
    const cache = group[G.CACHE]
    const newItem = {
      key: payload.id,
      _rev: payload.rev,
      value: {
        name: item.value.name,
        size: item.value.size,
        mimetype: item.value.type,
      },
    }
    data.splice(itemPos, 1)
    cache.unshift(newItem)

    // Once all files have been uploaded, emit new group data
    if (data.length === 0) {
      bus.dispatch(bus.type(G.ATTACHMENT, G.DONE, groupName), {
        [G.DATA]: group[G.DATA].concat(group[G.CACHE]),
        [G.STATE]: group[G.STATE],
      })
    }
  }
}

/**
 * MessageEvent Listener function executed on #postMessage from inside the {@link Worker}.
 *
 * @example of successful response {
 *  "ok":true,
 *  "id":"ATTACHMENT:PERSON:11:c66c0e2f-077f-4eab-8d6f-a463b3701610",
 *  "rev":"1-bb69bfd6efda2ae1af98b6bc3b517ce5"
 * }
 * @param {Worker} worker               the WebWorker object
 * @param {string} group                group name
 * @param {string} source               blob url
 * @param {Gaia.Adapter.EventBus} bus   the Native Event Bus Adapter
 * @param {function(Object): void} fn   the {@link onComplete} function
 * @returns {((MessageEvent) => void)}  the MessageEvent listener
 */
export const onMessage = (worker, group, source, bus, fn) => (evt) => {
  // eslint-disable-next-line object-curly-newline
  const { key, status, ref, response } = evt.data
  const type = ref ? G.DONE : G.READ
  const eventType = bus.type(G.ATTACHMENT, type, key)
  bus.dispatch(eventType, { ...evt.data })

  status === 201 && terminate(worker, source, group)()
  status === 201 && fn(response)
}

/**
 * Starts a WebWorker in order for it to upload the file contained inside the passed event's detail.
 * On successful upload, the file gets moved from the {@param groupName}'s group's (G.DATA) list of
 * local files to the group's (G.CACHE) list of remote files. Dispatches a DONE event with the
 * current local and remote ones afterwards.
 *
 * The default upload path, in principle depending on the current model, can be overridden by each
 * file through the group's add method by passing a [G.API] property to this method. The last
 * one has preference.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail }) => {
  const { key, name = '', api, value: item } = detail[G.DATA]
  const overrides = { draft: detail[G.API].draft, ...api, ...detail[G.API] }
  const createUrl = overrides.draft ? getDraftUrl : getCreateUrl
  const url = `${createUrl(app, overrides)}`
  const data = { url, item, key, name }

  const { source, worker } = createWorker(createWorkerBody)
  const onCompleteFn = onComplete(app, groupName, detail[G.DATA])
  worker.onmessage = onMessage(worker, groupName, source, app[G.ADAPTER][G.EVENTS], onCompleteFn)
  worker.onerror = terminate(worker, source)
  worker.postMessage(data)
}

export default fn
