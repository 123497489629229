/* eslint-disable no-param-reassign */
/* global G */
import { curry } from 'lib/util'

/**
 * Language middleware handler.
 *
 * Adds the current application language as 'Accept-Language' request header.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {function} next             the next middleware function
 * @param {Object} args               the request's arguments
 * @return {Object}                   the response object
 */
export default curry(async (obj, next, args) => {
  args.headers = {
    ...args.headers || {},
    'Accept-Language': obj[G.SESSION][G.STATE][G.LANGUAGE] || '*',
  }
  return await next(args)
})
