/* global G */

/**
 * Attempts to resent the last request with the given `tokenRequired`
 * in the `If-Match` request header.
 *
 * @param {Gaia.Web.Application} obj    the Web platform Application
 * @param {object} response             the server response
 * @param {object} args                 information about the last request
 * @returns {Promise<void>}
 */
const okHandler = async (obj, response, args) => {
  const { [G.HTTP]: httpAdapter } = obj[G.ADAPTER]
  const confirmationToken = response?.cause?.tokenRequired
  const { method, url, params, headers } = args

  return await httpAdapter[G.API]?.[method.toLowerCase()]?.({ url,
    params,
    headers: {
      ...headers,
      'If-Match': confirmationToken,
    } })
}

/**
 * Confirmation Required Dialog
 *
 * Displays a message informing the user that the server needs confirmation that the
 * current operation should indeed be performed. Pressing the Ok button will prompt
 * the client to resent the request with the received `cause.tokenRequired` in the
 * `If-Match` request header.
 *
 * To be displayed if the server returns a status code of `428` and a `response.error`
 * of `CONFIRMATION_REQUIRED`.
 *
 * @param {Gaia.Web.Application} obj    the Web platform Application
 * @param {object} response             the server response
 * @param {object} args                 information about the last request
 */
export default async (obj, response, args) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  return new Promise((resolve) => {
    eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
      title: obj[G.ADAPTER][G.INTL]._t(
        'dialog.error.confirmation.default.title',
        {
          ns: 'common',
          _key: 'dialog.error.confirmation.default.title',
          defaultValue: 'Warning',
        },
      ),
      text: obj[G.ADAPTER][G.INTL]._t(
        'dialog.error.confirmation.default.text',
        {
          ns: 'common',
          _key: 'dialog.error.confirmation.default.text',
          defaultValue: 'Your change will result in a significant adjustment of data. Would you still like to make them now?',
        },
      ),
      children: {
        ok: {
          key: 'ok',
          value: obj[G.ADAPTER][G.INTL]._t(
            'button.ok',
            {
              ns: 'common',
              _key: 'button.ok',
              defaultValue: 'Ok',
            },
          ),
        },
        cancel: {
          key: 'cancel',
          value: obj[G.ADAPTER][G.INTL]._t(
            'button.cancel',
            {
              ns: 'common',
              _key: 'button.cancel',
              defaultValue: 'Cancel',
            },
          ),
        },
      },
      okHandler: async () => {
        resolve(await okHandler(obj, response, args))
      },
    })
  })
}
