/* eslint-disable no-param-reassign */
/* global G */
import modelCreate from 'lib/sequence/model/api/create'
import modelTransform from 'lib/sequence/model/transformer'
import { asyncpipe, isArr } from 'lib/util'

/**
 * Retrieves id and rev from the request's {@param result} and updates the {@param model}'s
 * cache and state with their values.
 *
 * @param {Gaia.Model.Spec} obj model object composition
 * @param {Object} result       the submit request's response
 * @private
 */
const _updateModel = (obj, result) => {
  const state = obj[G.STATE]
  const cache = obj[G.CACHE] || {}
  // TODO: update id to key when/if the server does it
  const { ok, id, rev } = isArr(result)
    ? result[0] || {}
    : (result.data && result.data[0]) || {}
  if (ok) {
    state[G.REF] = id
    cache.key = id
    cache._rev = rev
  }
}

/**
 * Attempts to update an already existing {@param model} according to its state data and cache key.
 *
 * @param {Gaia.Model.Spec} obj model object composition
 * @returns {Promise<*>}
 */
export const updateData = async (obj) => {
  const { api, version } = obj[G.PROPS]
  const params = obj[G.STATE][G.DATA]
  const id = obj[G.CACHE].key || obj[G.CACHE][0].key
  const url = `/api/v${version}/${api}/${id}`
  const result = await obj[G.ADAPTER][G.HTTP].put({ url, params })
  _updateModel(obj, result)
  return obj
}

/**
 * Attempts to create a new model {@param model} according to its state data.
 *
 * @param {Gaia.Model.Spec} obj model object composition
 * @returns {Promise<*>}
 */
const createData = async (obj) => {
  const { api, version } = obj[G.PROPS]
  // results of seqModelx() are persisted in obj[G.STATE][G.DATA]...
  const params = obj[G.STATE][G.DATA]
  const url = `/api/v${version}/${api}`
  const result = await obj[G.ADAPTER][G.HTTP].post({ url, params })
  _updateModel(obj, result)
  return obj
}

/**
 * If {@param obj} has cache and an assigned id, this function executes {@link updateData},
 * otherwise it executes {@link createData}.
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @returns {Promise<*>}
 */
const saveData = obj => (obj[G.CACHE] && obj[G.STATE][G.REF] ? updateData(obj) : createData(obj))

/**
 * Model Submit Sequence.
 *
 * Executes model data remote submission, based on model cache and reference.
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @return {function(...[*]): Promise<*[]>}
 */
export default async obj => await asyncpipe(
  modelCreate,
  modelTransform,
  saveData,
)(obj)
