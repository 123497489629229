import { isObj } from 'lib/util/index'

/**
 * Formats a string by replacing its wildcards with variable values.
 *
 * @example
 *  format("Hello, {name}, are you feeling {adjective}?", {name:"Gabriel", adjective: "OK"});
 *  result: Hello, Gabriel, are you feeling OK?
 *
 *  format("Hello, {0}, are you feeling {1}?", "Gabriel", "OK"});
 *  result: Hello, Gabriel, are you feeling OK?
 *
 * Adaptation of the formatUnicorn function from StackOverflow: https://stackoverflow.com/a/18234317
 *
 * @param {string} string           the string to format
 * @param {Object|string[]} values  the values to replace the wildcards with
 * @returns {string}                the resulting string
 */
const format = (string, ...values) => {
  const isMarkdown = isObj(string)
  let result = isMarkdown ? string.props?.children || string.props?.text : string

  if (values.length) {
    const type = typeof values[0]
    const args = type === 'string' || type === 'number'
      ? Array.prototype.slice.call(values)
      : values[0]

    Object.keys(args).forEach((key) => {
      result = result.replace(new RegExp(`\\{${key}\\}`, 'gi'), args[key])
    })
  }

  return isMarkdown
    ? {
      ...string,
      props: {
        ...string.props,
        children: result,
        text: result,
      },
    }
    : result
}

/**
 * Add {@param string} to {@param file}. Works for both filenames with and without extensions.
 *
 * @param {String} string           the string to append or prepend
 * @param {String} file             the file name to append or prepend to
 * @param {String} [separator='-']  the separator between {@param file} and {@param string}
 * @param {Boolean} [append=true]   whether to append or prepend
 * @return {string}
 */
export const addToFilename = (string, file, separator = '-', append = true) => {
  const i = file.lastIndexOf('.')
  return i === -1
    ? (append && file + separator + string) || string + separator + file
    : (append && file.substring(0, i) + separator + string + file.substring(i))
      || string + separator + file
}

/**
 * Capitalizes {@param string}
 *
 * * Platform-agnostic variant. Please also mind {@code capitalizeFirstLetter} in
 * {@code client/platform/web/src/lib/react/hoc/children.jsx}
 *
 * @param {String} string the string to capitalize
 * @return {string}
 */
export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export default format
