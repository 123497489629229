/* eslint-disable no-empty,no-unused-vars */
/* global G */
import { curry, setKey } from '@gaia/util'

/**
 * Returns the name of a random property of {@param obj}.
 *
 * @param {Object} obj  object containing contexts
 * @return {string}     the name of a random property of obj
 * @private
 */
const _randomKey = (obj) => {
  const keys = Object.keys(obj)
  return keys[Math.floor(Math.random() * keys.length)]
}

/**
 * Handler for the proxy that wraps {@code meta.acl}
 * @type {{get(*, *, *): number[]|*}}
 * @private
 */
export const _aclProxyHandler = {
  get(target, prop, receiver) {
    return Object.keys(target).includes('*')
      ? [10, 20, 30, 40]
      : target?.[prop]
  },
}

/**
 * Initializes the application context by setting it from:
 *  1. the default context of the current User, if they have one; or
 *  2. a random context from among those available to the user.
 *
 *  The name of the context is set for the starting route (if it doesn't already have one) and also
 *  for the whole application by storing it within {@code app[G.SESSION][G.STATE][G.CONTEXT]}, which
 *  is also accessible by the application's modules through the
 *  {@code module[G.ADAPTER][G.ROUTER].context()} method.
 *
 * @type {(function(...[*]): (*))|*}
 */
const init = curry(async (obj, args) => {
  try {
    const meta = obj[G.ADAPTER][G.SESSION][G.STATE][G.META]
    const { context, refs: { settings: [settings] = {} } = {} } = meta || {}
    const { value: { lastContext = false } = {} } = settings || {}
    const randomContext = _randomKey(context)
    const newContext = context[lastContext]
      ? lastContext
      : randomContext

    meta.acl = new Proxy(context[newContext].acl, _aclProxyHandler) // setting user's acls according to active context
    meta.roles = context[newContext].roles // setting user's acls according to active context
    setKey(newContext, G.CONTEXT, obj[G.SESSION][G.STATE]) // global context's location
  } catch (ignored) {} // We keep the default route coming from route init

  return args
})

const contextInit = curry(init)

export default contextInit
