/* global G */
import _create from '@platform/adapter/settings/helper/settings'
import _update from '@platform/adapter/settings/helper/user'
import { getFirstItem } from 'lib/util'

/**
 * Create the remote user settings document and updates the remote user with the ID
 * of the newly created userSettings document.It also updates the user in the local
 * session with the new ref.
 *
 * Dispatches {@code G.SETTINGS, G.DONE} event afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {(function(): Promise<void>)|*}
 */
const fn = app => async () => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapter = app[G.ADAPTER][G.SETTINGS]
  const sessionAdapter = app[G.ADAPTER][G.SESSION]
  const state = adapter[G.STATE]

  const user = getFirstItem(sessionAdapter[G.STATE][G.META].refs.user)

  const { [G.API]: api } = state

  try {
    // Creating the user settings on the server
    const response = await _create(app, { user, api })

    if (response.ok) {
      adapter[G.STATE].key = response.id

      // Updating the user document with the new settings
      const alteredUser = await _update(app, { settings: response, user, api })

      if (alteredUser.ok) {
        // Updating adapter state and user ref with the new values
        adapter[G.STATE].key = response.id
        adapter[G.STATE]._rev = response.rev
        adapter[G.STATE].refs = { submitter: [user.key] }

        const value = { status: 50, favorites: [] }
        adapter[G.DATA] = value
        sessionAdapter[G.STATE][G.SETTINGS] = value

        user._rev = alteredUser.rev
        user.refs.settings = [response.id]
      }
    }
  } catch (e) {
    console.error(e)
  }

  eventBus.dispatch(eventBus.type(G.SETTINGS, G.DONE))
}

export default fn
