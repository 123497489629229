/* global G */
import { getUrl } from '@platform/adapter/message/helper'

/**
 * Attempts to activate a draft message on the server.
 * On success, it also updates it from the adapter's
 * data and then dispatches a G.DONE event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapter = app[G.ADAPTER][G.MESSAGE]
  const adapterData = adapter[G.DATA]
  const item = detail[G.DATA]
  const callback = detail[G.FN]

  /**
   * We need to use a view here, because we want the refs
   * to be resolved.
   */
  const url = `${getUrl(app, { key: item.key })}/default`
  const options = { middleware: ({ persistence, error }) => [persistence, error] }
  const result = await app[G.ADAPTER][G.HTTP][G.API].put({ url, params: item }, options)

  if (result) {
    const adapterItem = adapterData.find(dataItem => dataItem.key === item.key && dataItem.draft)
    delete adapterItem.draft

    adapterItem.acl = result.acl
    adapterItem._rev = result._rev
    adapterItem.refs = result.refs
    adapterItem.value = {
      ...result.value,
      attachments: adapterItem.value.attachments,
    }

    callback?.()

    eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
