/* eslint-disable no-param-reassign */
/* global G */
import { curry } from 'lib/util'

/**
 * Context middleware handler.
 *
 * Adds the current application context as 'x-gaia-context' request header.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {function} next             the next middleware function
 * @param {object} args               the request's arguments
 * @return {object}                   the response object
 */
export default curry(async (obj, next, args) => {
  const context = obj[G.SESSION][G.STATE][G.CONTEXT]
  context && (args.headers = {
    'x-gaia-context': context,
    ...args.headers || {},
  })
  return await next(args)
})
