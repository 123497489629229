/* global G */
import { pipe, curry } from 'lib/util'
import { hasNamespace } from 'trait/has'
import { usesNamespace } from 'trait/uses'
import { withDescriptor, withObjectFreeze, withGetterSetter } from 'trait/with'

const { CONFIGURATION } = G

/**
 * Module Object base structure.
 * Sets configuration symbol getter in an object,
 * which will be used as prototype in module composition
 *
 * @param {Gaia.AppModule.Spec | Gaia.Component.Spec} configuration - {@link Gaia.Component.Spec}
 * @return {Object} module object
 */
const moduleObject = configuration => ({
  get [CONFIGURATION]() {
    return { ...configuration }
  },
})

/**
 * Module Composition.
 *
 * Creates a new object to be used as root of composition.
 *
 * @param {string} descriptor
 * @param {Gaia.AppModule.Spec || Gaia.Component.Spec} configuration - {@link Gaia.Component.Spec}
 * @return {module} module composition
 */
const moduleComposition = (descriptor, configuration) => pipe(
  withDescriptor(descriptor),
  hasNamespace(G.STATE),
  hasNamespace(G.PROPS),
  usesNamespace(G.PROPS, withObjectFreeze(configuration.options)),
)(moduleObject(configuration))


/**
 * Module Composition
 *
 *
 * @param {string} descriptor
 * @return {function(*=): *} composition
 */
const composition = descriptor => pipe(
  curry(moduleComposition)(descriptor),
  hasNamespace(G.API),
  withGetterSetter(G.ACL),
)

export default composition
