/* global G */
import {getUrl, noLoaderMiddleware} from '@platform/adapter/note/helper'
import { defaultMiddleware } from 'platform/adapter/http/middleware'

/**
 * Adds a note to the adapter's G.DATA list of messages, dispatches a G.DONE event and then
 * attempts to send it to the server. On successful send, it sets the new note's id and rev and
 * submitTimestamp, adds it to the adapter's data and dispatches a G.DONE event again.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const adapter = app[G.ADAPTER][G.NOTE]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapterData = adapter[G.DATA]

  const item = detail[G.DATA]
  const hasAttachments = detail[G.ATTACHMENT]
  const callback = detail[G.FN]

  adapterData.unshift(item)

  eventBus.dispatch(eventBus.type(G.NOTE, G.DONE), { [G.DATA]: adapterData })

  // Initializing the loader if we have attachments
  hasAttachments && eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))

  const url = `${getUrl(app)}/default`
  const result = await app[G.ADAPTER][G.HTTP][G.API].post({ url, params: item }, {
    middleware: hasAttachments
      ? noLoaderMiddleware
      : defaultMiddleware,
  })

  if (result) {
    item.key = result.key
    item._rev = result._rev
    item.value = result.value
    item.refs = result.refs
    item.acl = result.acl

    const done = await callback?.(item)

    // Closing the loader
    done && hasAttachments && eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

    eventBus.dispatch(eventBus.type(G.NOTE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
