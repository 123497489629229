/* eslint-disable object-curly-newline,no-restricted-globals */

/**
 * Local Attachment Create Worker Body
 *
 * Will keep track of the current request by using a timeout. The timeout gets reset everytime
 * `onprogess` is called as well as after the request is finished, in `onload`. If the timer
 * runs out, meaning the next chunk is not received by the server within `timeoutDuration`,
 * it will abort the request using `abortHandler`.
 *
 * NOTE: All the functions/variables regarding timeout/abort have to be inline, if they are
 * defined outside the returned function we lose their references due to different scope.
 *
 * NOTE: is a closure with context of DedicatedWorkerGlobalScope babel transformation error when
 * inline excluded via webpack config current usage of arrow functions needs to be evaluated on
 * multiple OS and browsers, as ES6 native support might still be janky on some constellations
 * alternatives would be to evaluate webpack 5, otherwise use worker-loader
 *
 */
export default () => {
  let timeoutId
  const timeoutDuration = 5000
  const _worker = self

  const clearHandler = () => { clearTimeout(timeoutId) }
  const timeoutHandler = (fn) => {
    timeoutId && clearHandler()
    timeoutId = setTimeout(fn, timeoutDuration)
  }

  const abortHandler = (e) => {
    clearHandler()
    const { key, uuid } = e.data
    _worker.postMessage({ key, uuid, progress: 0, status: 408 /* request timeout */ })
  }

  _worker.addEventListener('message', (evt) => {
    const { url, key, uuid } = evt.data
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.open('get', url, true)
    timeoutHandler(() => { xhr.abort() })
    // xhr.setRequestHeader('Cache-Control', 'max-age=3600, max-stale') // no effect, it seems

    xhr.onprogress = (event) => {
      timeoutHandler(() => { xhr.abort() })

      const done = event.position || event.loaded
      const total = event.totalSize || event.total

      const progress = Math.floor((done / total) * 1000) / 10
      _worker.postMessage({ key, uuid, done, total, progress, status: xhr.status })
    }

    xhr.onerror = () => { xhr.abort() }
    xhr.onabort = () => { abortHandler(evt) }

    xhr.onload = () => {
      clearHandler()
      _worker.postMessage(xhr.status !== 200
        ? { key, url, uuid, done: 0, total: 100, progress: 0, status: xhr.status }
        : { key, url, uuid, blob: xhr.response })
    }
    xhr.send()
  })
}
