/* eslint-disable implicit-arrow-linebreak,no-param-reassign */
/* global G */
import { pipe, setKey, deleteKey } from 'lib/util'
import { withDependencyCheck } from 'trait/with'

const descriptor = 'sequence::model::reset'

/**
 * Preliminary Check for sequence required attributes.
 *
 * @param obj - model object composition
 * @return {*}
 */
const checkDeps = (obj) => {
  withDependencyCheck(`${descriptor} model`, [G.STATE], obj)
  return obj
}

/**
 * Deletes all enumerable properties from {@param object}.
 *
 * @param {Object} object the object to clear
 */
const clear = object => Object.keys(object).forEach(key => delete object[key])

/**
 * Model Children Apply
 *
 * Executes {@param fn} for each {@code obj}'s child and calls itself recursively.
 *
 * @param {function} fn - callback function
 * @return {function(object): object} obj - model object composition
 */
const childrenApply = fn => obj =>
  (obj[G.CHILDREN]
    ? Object.values(obj[G.CHILDREN]).reduce((acc, child) =>
      fn(child) && childrenApply(fn)(child) && obj, obj)
    : obj)

/**
 * Model Reset
 *
 * Resets model and it's children to initial state, recursively.
 *
 * @param {Object} [options]          additional process options
 * @param {boolean} [options.clear]   whether to also clear error states
 * @return {*}
 */
const reset = options => (obj) => {
  setKey(null, G.CACHE, obj)
  clear(obj[G.DATA])
  obj[G.REF] && deleteKey(G.REF, obj)
  obj[G.STATE]
    && deleteKey(G.REF, obj[G.STATE])
    && deleteKey(G.DATA, obj[G.STATE])
    && deleteKey(G.BULK, obj[G.STATE])
    && options.clear && deleteKey(G.ERROR, obj[G.STATE])
  return obj
}

/**
 * Model Rest Sequence.
 *
 * Resets model instance
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @param {Object} [options]          additional process options
 * @param {boolean} [options.clear]   whether to also clear error states
 * @return {function(*=): *}
 */
export default (obj, options = {}) => pipe(
  checkDeps,
  reset(options),
  childrenApply(reset(options)),
)(obj)
