/* eslint-disable no-param-reassign */
import { forwardRef, useCallback, useRef, useState } from 'react'
import Section from 'ui/Component/Grid/Section'
import Drawer from 'ui/Component/Layout/Drawer'
import ErrorBoundary from 'ui/Error'

const ForwardedRefSection = forwardRef(Section)

/**
 * Processes the passed components by:
 * - Filtering out those that have the showOnLimit prop, if limitReached is false
 * - Setting showListDrawer as the onClick event handler to those that have the showAllAction prop
 *
 * @param {React.ReactNode[]} components    components to process according to options
 * @param {Object} options                  options considered to process the components
 * @param {boolean} options.limitReached    whether the list is full
 * @param {Function} options.showListDrawer handler used to show the dedicated list drawer
 * @return {React.ReactNode[]}              the resulting components
 */
const process = (components, { limitReached, showListDrawer }) => {
  return components.reduce((acc, component) => {
    const { showOnLimit, showAllAction, children, ...restProps } = component.props
    if (!showOnLimit || (showOnLimit && limitReached)) {
      // shallow copying action, so that we can edit its props
      const { ...item } = component
      item.props = restProps
      item.props.children = process(children, { limitReached, showListDrawer })
      if (showAllAction) {
        item.props.events = {
          ...item.props.events,
          onClick: showListDrawer,
        }
      }
      acc.push(item)
    }
    return acc
  }, [])
}

/**
 * Extends StructuredList by adding the possibility to assign actions to it.
 *
 * Any action component at any depth can make use of the following props:
 * - `showOnLimit`: The action will only be shown if the wrapped list is full (`data` >= `limit`)
 * - `showAllAction`: On click, the action will open the wrapped list in a dedicated `Drawer`,
 *    making use of the `drawerTitle` and of the `onShowAll` event handler props.
 *
 * @param {function(*, *)} StructuredListComponent
 * @return {function(*, *)}
 * @constructor
 */
const ListActionsHOC = StructuredListComponent => ({ actions = [], ...props }, ref) => {
  const { drawerTitle, limit, refName, events = {}, ...drawerListProps } = props
  const { onPage, onShowAll, onOpen, ...listEvents } = events

  const [limitReached, setLimitReached] = useState(false)
  const drawerRef = useRef()

  // handler passed to the list drawer to close itself
  const closeListDrawer = useCallback(() => {
    drawerRef.current.setState({ open: false, title: '', children: [] })
  }, [drawerRef])

  // handler wrapping the onShowAll/onOpen functions. Passed to the list
  // drawer, so that it performs the corresponding onOpen action
  const openList = useCallback(async (event) => {
    event.detail.limit = 20
    return onShowAll ? await onShowAll(event) : await onOpen(event)
  }, [onShowAll])

  // handler passed to any action component with the showAllAction prop,
  // so that the list drawer is open when the action is clicked
  const showListDrawer = useCallback(() => {
    drawerRef.current.setState({
      open: true,
      title: drawerTitle,
      children: [
        <StructuredListComponent
          {...drawerListProps}
          key={'drawerList'}
          forwardedRef={ref}
          header={null}
          headerLabel={null}
          unsegmented
          events={{
            ...listEvents,
            onOpen: openList,
            onClose: closeListDrawer,
          }}
        />,
      ],
    })
  }, [drawerTitle, drawerListProps, listEvents, openList, closeListDrawer])

  // handler wrapping onPage to record if the current amount of
  // data has reached the list's limit
  const handleOnPage = useCallback((event) => {
    const { data } = event.detail
    setLimitReached(data.length >= limit)
    onPage?.(event)
  }, [onPage, limit])

  // processes actions, making them consider the passed options
  // according to the HOC description
  const processedActions = process(actions, {
    limitReached,
    showListDrawer,
  })

  return (
    <>
      <StructuredListComponent
        {...props}
        forwardedRef={ref}
        events={{
          ...listEvents,
          onOpen,
          onPage: handleOnPage,
        }}
      />
      <ErrorBoundary>
        <ForwardedRefSection
          classes={['end', 'itemsCenter']}
          marginTop={1}
          marginBottom={1}
          gap={1}
        >
          {processedActions}
        </ForwardedRefSection>
        <Drawer
          ref={drawerRef}
          width={'56rem'}
          maxWidth={'xl'}
        />
      </ErrorBoundary>
    </>
  )
}

export default ListActionsHOC
