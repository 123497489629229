/* global G */

export const routeToHash = route => `#/${[
  route[G.CONTEXT],
  route[G.MODULE],
  route[G.ACTION],
  route[G.REF],
].filter(part => !!part).join('/')}/`

/**
 * Checks whether {@param route} and {@param other} are equivalent routes.
 *
 * @param {Gaia.Route} route  a route to compare with {@param other}
 * @param {Gaia.Route} other  a route to compare with {@param route}
 * @returns {boolean}
 */
const isSameRoute = (route, other) => route && other
  && route[G.CONTEXT] === other[G.CONTEXT]
  && route[G.MODULE] === other[G.MODULE]
  && route[G.ACTION] === other[G.ACTION]
  && route[G.REF] === other[G.REF]

/**
 * Can Stack Current Action on Nav Stack Tester.
 *
 * Takes current action's [G.UI] settings, and checks for specific flags.
 *
 * Flag - Modal - this action is not used in Stack, and rendered inside platform's modal ui
 * Flag - History - has to be set to true, in order to persist action on application nav stack.
 *
 * @param {Gaia.AppModule.Action.Ui} ui action's ui configuration
 * @param {boolean} ui.noStack          whether the action is forced not to be added to the stack
 * @param {boolean} ui.history          whether the action is to be added to the history stack
 * @return {boolean} result can action be used on nav stack
 * @private
 */
const _canStack = ui => !ui.noStack && ui.history // && !ui.modal && !ui.drawer

/**
 * Adds the previous route to the history stack if:
 * - it's different from the last one,
 * - its action has the ui history flag set to true and
 * - its action has the ui modal flag set to false
 *
 * @param {Gaia.Web.Application} obj
 * @returns {function(...[*]): *[]}
 */
const setAppHistory = obj => (...args) => {
  const sessionState = obj[G.SESSION][G.STATE]
  const stack = sessionState[G.PREV]
  const route = obj[G.STATE][G.ROUTE] // previous route
  const action = sessionState?.[G.MODULE]?.[G.STATE]?.[G.ACTION] // current action
  const ui = action && action[G.UI]
  const canStack = ui && _canStack(ui)
  const lastEntry = stack[stack.length - 1]

  if (canStack && (!lastEntry || !isSameRoute(route, lastEntry))) {
    // setting action data to route's cache
    route[G.CACHE] = action[G.DATA]
    // clearing action data
    action[G.DATA] = {}
    // adding route to stack
    stack.push(route)
  }

  ui && delete ui.noStack

  return args
}

export default setAppHistory
