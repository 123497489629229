import { curry, getFirstItem, isObj } from 'lib/util'
import showConfirmationRequiredErrorDialog from 'platform/adapter/http/middleware/error/dialog/confirmation'
import showForbiddenCircularityErrorDialog from 'platform/adapter/http/middleware/error/dialog/forbidden/circularity'
import showForbiddenOrgRefsUpdateRunningErrorDialog
  from 'platform/adapter/http/middleware/error/dialog/forbidden/orgRefsUpdateRunning'
import showForbiddenThresholdExceededErrorDialog
  from 'platform/adapter/http/middleware/error/dialog/forbidden/thresholdExceeded'
import showConflictErrorDialog from 'platform/adapter/http/middleware/error/dialog/conflict'
import showForbiddenErrorDialog from 'platform/adapter/http/middleware/error/dialog/forbidden'
import showUnauthorizedErrorDialog from 'platform/adapter/http/middleware/error/dialog/unauthorized'
import showErrorDialog from 'platform/adapter/http/middleware/error/dialog/generic'
import showTooManyRequestsErrorDialog from 'platform/adapter/http/middleware/error/dialog/tooManyRequests'
import redirectToErrorAction from 'platform/adapter/http/middleware/error/action/redirectToErrorAction'
import helper from 'platform/adapter/http/helper'
import checkConnectivity from 'platform/adapter/http/middleware/error/action/checkConnectivity'

/**
 * Error codes that require a confirmation from the client.
 *
 * @type {string[]}
 */
const confirmationErrorCodes = [
  'CONFIRMATION_REQUIRED',
]

/**
 * Mapping between status (and possibly error) codes and dialogs.
 * `*` is used as a fallback, if the mapping fails.
 */
const dialogs = {
  400: showErrorDialog,
  401: showUnauthorizedErrorDialog,
  403: {
    THRESHOLD_2_EXCEEDED: showForbiddenThresholdExceededErrorDialog,
    ORGREFS_UPDATE_RUNNING: showForbiddenOrgRefsUpdateRunningErrorDialog,
    DATA_CIRCULARITY: showForbiddenCircularityErrorDialog,
    '*': showForbiddenErrorDialog,
  },
  409: showConflictErrorDialog,
  412: {
    PRECONDITION_FAILED: showErrorDialog,
    '*': showErrorDialog,
  },
  428: {
    CONFIRMATION_REQUIRED: showConfirmationRequiredErrorDialog,
    '*': showErrorDialog,
  },
  429: showTooManyRequestsErrorDialog,
  500: showErrorDialog,
}

/**
 * Mapping between error codes and actions.
 *
 * MAYBE: For now, this is pretty useless, we have the same handler for every error type.
 *   What we could do is to have a separate handler for each type that redirects to a different
 *   error action. However, I don't think it's good UX to have `error/notFound`,
 *   `error/noConnection`, etc. in the URL depending on the error. This should be obfuscated
 *   from the user. Still, I think it's a good idea to have this mapping here instead of
 *   executing {@link redirectToErrorAction} directly in case we ever need to do something
 *   before we redirect that is out of the error module's scope.
 */
const actions = {
  404: redirectToErrorAction,
  403: redirectToErrorAction,
  FETCH_FAILED: (...args) => {
    checkConnectivity(...args)
    redirectToErrorAction(...args)
  },
  '*': redirectToErrorAction,
}

/**
 * Function to decide what dialog to show based on the received `code`
 * and possible `response.error`.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {number} code               the http status code
 * @param {object} response           the server response
 * @param {string} response.error     the reason the server rejected the request
 * @param {object} args               information about the request, like url, params, etc
 * @returns {*}
 */
const showDialog = (obj, { code, response, args }) => {
  const needsConfirmation = confirmationErrorCodes.includes(response?.error)
  const targetDialog = !isObj(dialogs[code])
    ? dialogs[code]
    : dialogs[code]?.[response.error] || dialogs[code]['*']

  return needsConfirmation
    ? targetDialog?.(obj, response, args)
    : targetDialog?.(obj)
}

/**
 * Function to decide what action handler to call based on `code`.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {number} code               the http status code
 * @param {string} [key]              the key of the document we tried to query
 * @param {object} response           the server response
 */
const showAction = async (obj, { code, key, response }) => {
  const targetAction = actions?.[code] || actions['*']

  await targetAction?.(obj, { code, key, response })
}

/**
 * Error middleware handler.
 *
 * Shows either and error dialog or redirects to an action based on the `args.onError` property.
 * The dialog or action is chosen depending on the HTTP status code received in a server's response.
 *
 * Will await the action handler.
 * Will await the dialog handler if it's ok handler is async and resolves on button press.
 *
 * If it returns a response itself, e.g. in the case of {@link showConfirmationRequiredErrorDialog},
 * it will return this response. Otherwise, it will throw an error.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {function} next             the next middleware function
 * @param {Object} args               the request's arguments
 * @return {Object}                   the response object
 */
export default curry(async (obj, next, args) => {
  let result
  try {
    result = await next(args)
  } catch (e) {
    const error = helper._mapError(e, args)

    if (args.onError === 'dialog') {
      const confirmedResult = await showDialog(obj, { code: error.code, response: error.response, args })
      const response = getFirstItem(confirmedResult)

      if (response?.ok) {
        return response
      }
    } else if (args.onError === 'action') {
      await showAction(obj, { code: error.code, key: error.key, response: error.response })
    }

    throw error
  }
  return result
})
