/* eslint-disable object-curly-newline,no-param-reassign */
/* global G */
import { curry, pipe, setKey } from '@gaia/util'
import routeComposition from 'trait/composition/route'

// const descriptor = 'seq::app::init::route'

let _path

/**
 * Path Parser
 *
 * @param {Gaia.Web.Application} obj - application composition
 * @return {Object} path - given path structure
 * @private
 */
const _parsePath = (obj) => {
  _path = _path || obj[G.ADAPTER][G.ROUTER][G.API].path()
  return _path
}

/**
 * Check that the initial path is among those to which we want to allow external access. If that's
 * the case, clears the session error so that the application doesn't redirect the user to the login
 * screen.
 *
 * @param {Gaia.Web.Application} obj  - application composition
 * @returns {Gaia.Web.Application}    - application composition
 * @private
 */
const _checkAccess = (obj) => {
  const { module, action, key, token } = _parsePath(obj)
  const { reset, verify, verifyLogin, register, invite } = obj[G.CONFIGURATION].defaults
  const routes = [reset, verify, verifyLogin, register, invite]

  if (routes.some(route => route.module === module && route.action === action)) {
    const sessionState = obj[G.SESSION][G.STATE]
    if (sessionState[G.META]) {
      // At this point, the user is attempting to go to a pre-login route with an active session,
      // so we set the limit to redirect them to the default route instead, and show an alert
      // instructing them to log out first (GAIA-1143).
      sessionState[G.LIMIT] = true
    } else {
      sessionState[G.ERROR] = null
      sessionState[G.REF] = token ? `${key}/${token}` : key
    }
  }
  return obj
}

/**
 * Checks whether the route set through the URL doesn't have a module or it is different from the
 * unregistered route's module and whether the limit flag is set. If any of those conditions is
 * true, returns the default route, otherwise returns the route set through the URL.
 *
 * @param {Gaia.Web.Application} obj - application composition
 * @return {{context: string, module: string, action: string, [key]: string}} - route parameters
 * @private
 */
const _given = (obj) => {
  const sessionState = obj[G.SESSION][G.STATE]
  const defaultRoute = obj[G.ADAPTER][G.ROUTER][G.API].defaultRoute()
  const unregisteredRoute = obj[G.CONFIGURATION].defaults.unregistered
  return !_path.module || _path.module === unregisteredRoute.module || sessionState[G.LIMIT]
    ? defaultRoute : _path
}

/**
 * Sets application default context for the initial route if it has none yet.
 *
 * @param {Gaia.Web.Application} obj - application composition
 * @private
 */
const _context = obj => (route) => {
  route.context = route.context
    || obj[G.SESSION][G.STATE][G.CONTEXT]
    || obj[G.CONFIGURATION].defaults.context
  return route
}

/**
 * Route Parameters Reset on Error Propagation
 *
 * @param {Gaia.Web.Application} obj - application composition
 * @return {{context: string, module: string, action: string, [key]: string}} - route parameters
 * @private
 */
const _route = obj => (obj[G.SESSION][G.STATE][G.ERROR]
  ? obj[G.CONFIGURATION].defaults.unregistered
  : _given(obj))

/**
 * Composition Initializer
 *
 * @param {{context: string, module: string, action: string, [key]: string}} route - route
 *   parameters
 * @return {Gaia.Route} route composition
 * @private
 */
const _composition = route => routeComposition(route.module, route.action, route.key, route.context)

/**
 * Application State Setter
 *
 * @param {Gaia.Web.Application} obj - application composition
 * @return {function(*): *}
 * @private
 */
const _state = obj => (route) => {
  const state = obj[G.STATE]
  state[G.ROUTE] = route
  return route
}

/**
 * Sets the route context as the global one.
 *
 * @param {Gaia.Web.Application} obj - application composition
 * @return {function(*): *}
 * @private
 */
const _session = obj => (route) => {
  const sessionState = obj[G.SESSION][G.STATE]
  setKey(route[G.CONTEXT], G.CONTEXT, sessionState)
  return route
}

const _setRestorePointOnError = (obj) => {
  const route = obj[G.ADAPTER][G.ROUTER][G.API].path()
  !!obj[G.SESSION][G.STATE][G.ERROR]
  && route.module
  && route.action
  && obj[G.CONFIGURATION].defaults.context !== route.context
  && obj[G.ADAPTER][G.ROUTER][G.API].setRestorePoint(route)

  return obj
}

/**
 * Sequence Application Init Route
 *
 * initializes route composition to be used during the app init sequence
 *
 * it sets different routes, such as guest authorisation, based on application session state
 *
 * @param {Gaia.Web.Application} obj - application composition
 * @param {[*]} args
 * @return {*}
 */
const sequenceAppInitRouteFn = (obj, args) => pipe(
  _checkAccess,
  _setRestorePointOnError,
  _route,
  _context(obj),
  _composition,
  _state(obj),
  _session(obj),
  route => (args ? [route, args] : [route]),
)(obj)

const sequenceAppInitRoute = curry(sequenceAppInitRouteFn)

export default sequenceAppInitRoute
