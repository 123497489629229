/* global G */

/**
 * Forbidden Circularity Dialog
 *
 * Displays a message informing the user that the attempted update would lead to a
 * circular dependency amongst organisations
 *
 * To be displayed if the server returns a status code of `403` and a `response.error`
 * of `DATA_CIRCULARITY`.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.circularity.title',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.circularity.title',
        defaultValue: 'Error',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.circularity.text',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.circularity.text',
        defaultValue: 'Your change leads to a circular configuration. Please change your entries',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })
}
