import { useEffect, useState } from 'react'

/**
 * List of groups we have a placeholder for.
 *
 * Each element corresponds to a placeholder located in
 * `client/platform/web/src/resources/img/placeholder`. If the mime group
 * of the given attachment is not listed here (like with `text`), it means
 * we don't have a placeholder for this group.
 *
 * NOTE: If we add a new placeholder to the path given above, we also need to
 * add the element here.
 *
 * @type {string[]}
 */
const knownMimeGroups = [
  'audio',
  'doc',
  'image',
  'pdf',
  'rar',
  'video',
  'xls',
  'zip',
]

/**
 * usePlaceholder Hook
 *
 * Shows a placeholder for the given `attachment`. It does so by getting the mime group of the
 * attachment based on the provided `types`. It then compares the group with the list of
 * {@link knownMimeGroups} and returns a placeholder for that group, if it's contained, or the
 * `other` fallback placeholder if it is not.
 *
 * @param {Object} types        known file types
 * @param {Object} attachment   the attachment to show a placeholder for
 * @param {*[]} deps            list of dependencies
 * @returns {unknown}
 */
const usePlaceholder = (types, attachment, deps = []) => {
  const [placeholder, setPlaceholder] = useState(null)

  useEffect(() => {
    const extension = attachment.name.slice(attachment.name.lastIndexOf('.') + 1)
    const group = Object.keys(types).find(id => types[id].includes(extension.toLowerCase()))

    const filename = knownMimeGroups.includes(group)
      ? `placeholder/${group}.svg`
      : 'placeholder/other.svg'

    setPlaceholder(filename)
  }, [types, attachment, ...deps])

  return placeholder
}

export default usePlaceholder
