/* eslint-disable no-param-reassign,object-curly-newline,no-undefined */
/* global G, React */
import { asyncpipe, curry, setKey } from '@gaia/util'
import { _create, _isDrawer, _isModal } from './create'

/**
 *  Options to Props Converter.
 *
 * It returns Component configuration options, extended by default properties such as ref,
 * as React component properties object.
 *
 * @param item
 * @return {DefaultProps|OptionsProps}
 * @private
 */
const _optionsToProps = item => ({
  ...item[G.REF].props,
  ...item[G.STATE],
  events: item[G.EVENTS],
})

/**
 * Modifies {@param item} by removing from it all its React element's props except those which were
 * added by {@link _create}.
 *
 * As the previous component's state and element's props were merged and set as the element's
 * current props through React's cloneElement, a new merge of said state and props would therefore
 * add all properties from the previous state (they are now in props) that do not exist in the
 * current one (including e.g. 'value'), preventing us from actually clearing any state prop
 * from the element. This function clears the previous state still being kept by the React
 * element's props.
 *
 * FIXME: its use prevents internationalization from working properly. We should find another way to
 *  clear the state from elements' props, like only using the "value" property for actual
 *  values, i.e. giving buttons it's text through a (e.g.) label property
 * @deprecated
 *
 * https://reactjs.org/docs/react-api.html#cloneelement
 * @see _optionsToProps
 *
 * @param {Gaia.Component} item - component composition
 * @return {Gaia.Component}
 */
const resetProps = (item) => {
  const { props, ...itemRef } = item[G.REF]
  const { events, subscription } = props
  itemRef.props = {
    ...item[G.PROPS],
    ...events && { events },
    ...subscription && { subscription },
    key: undefined, // without it, React recreates the element
  }
  setKey(itemRef, G.REF, item)
  return item
}

/**
 * React Element Cloner
 *
 * @param {Gaia.Component} item - component composition
 * @param {function} fn - function used to map children
 * @return {React.FunctionComponentElement<unknown>}
 * @private
 */
const _cloneElement = (item, fn) => {
  const props = _optionsToProps(item)

  if (item[G.ACTIONS]) {
    props.actions = item[G.ACTIONS].map(fn)
  }

  return React.cloneElement(
    item[G.REF],
    props,
    item[G.CHILDREN] && item[G.CHILDREN].map(fn),
  )
}

/**
 * Children Iterator.
 *
 * Used to clone existing UI
 * Creates new UI on demand
 *
 * @param {Gaia.Web.Providers.EventBus} eventBus - event bus
 * @return {function(item:Gaia.Component):React.FunctionComponentElement<DefaultProps|OptionsProps>}
 * @private
 */
const _clone = eventBus => item => (item[G.REF] === null
  ? _create(eventBus)(item)
  : _cloneElement(item, _clone(eventBus)))

/**
 * If something during the app init sequence went terribly wrong that prevented us from
 * rendering the module instance (client/src/lib/sequence/app/init.js:89), we don't have
 * `app[G.REF]` set. Even if we are trying to recover from this error by showing the
 * error page, we can't since the UI adapter is broken at this point because of the missing
 * ref.
 *
 * This happens (for example) if we try to access a module that doesn't exist. E.g. hitting
 * `localhost:1336/#/csm/foo` in a new tab.
 *
 * If this happens, we need to render the module instance again (at this point we already
 * are in the `error` module, because we recovered before, we just don't have a ref to the
 * app).
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Gaia.AppModule.Spec} fire and forget
 */
const renderModuleInstance = async (app, obj) => {
  if (!app[G.REF]) {
    await obj[G.ADAPTER][G.UI].render(obj)
  }

  return obj
}

/**
 * Update Content.
 *
 * Presents new or updated content.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Gaia.AppModule.Spec} fire and forget
 */
const updateContent = (app, obj) => {
  const parentComponent = app[G.REF].getContent()
  const { [G.ACTION]: action } = obj[G.STATE]
  const actionComponent = action[G.COMPONENT]
    || throw ReferenceError(`
    action - ${app[G.STATE][G.ROUTE][G.MODULE]}::${app[G.STATE][G.ROUTE][G.ACTION]} - seems to be missing from module configuration. 
    OR the action is a pure business logic, which should redirect somewhere
    `)
  const modalOrDrawer = _isModal(action[G.UI].modal, app) || _isDrawer(action[G.UI].drawer, app)

  if (modalOrDrawer && actionComponent[G.REF]) {
    // no need to update current content, since the focus is being changed to modal
    return obj
  }
  if (!modalOrDrawer) {
    actionComponent[G.REF] && parentComponent.setState({
      scroll: true,
      ...action[G.UI].content || {},
      fullScreen: action[G.UI].fullScreen,
      appBar: action[G.UI].appBar,
      ...actionComponent[G.STATE],
      children: [_clone(app[G.EVENTS])(actionComponent)],
    })
    !actionComponent[G.REF] && app[G.ADAPTER][G.UI][G.API].create(obj)
  }
  return obj
}

/**
 * Update Modal Context.
 *
 * Automates modal presentation, including content.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Gaia.AppModule.Spec} fire and forget
 */
const updateModal = (app, obj) => {
  const parentComponent = app[G.REF].getDialog()
  const { [G.ACTION]: action } = obj[G.STATE]
  const actionComponent = action[G.COMPONENT]
  const isOpen = parentComponent.state.open
  const willOpen = _isModal(action[G.UI].modal, app)

  if (willOpen) {
    // console.warn('UI::UPDATE', willOpen, isOpen, actionComponent[G.CHILDREN][1][G.STATE])
    actionComponent[G.REF] && parentComponent.setState({
      open: true,
      title: action[G.UI].title || '',
      ...actionComponent[G.STATE],
      children: [_clone(app[G.EVENTS])(actionComponent)],
    })
    !actionComponent[G.REF] && app[G.ADAPTER][G.UI][G.API].create(obj)
  } else if (isOpen && isOpen !== willOpen) {
    parentComponent.setState({
      open: false,
      ...actionComponent[G.STATE],
      children: [],
    })
  }
  return obj
}

/**
 * Update Modal Context.
 *
 * Automates modal presentation, including content.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Gaia.AppModule.Spec} fire and forget
 */
const updateDrawer = (app, obj) => {
  const parentComponent = app[G.REF].getDrawer()
  const { [G.ACTION]: action } = obj[G.STATE]
  const actionComponent = action[G.COMPONENT]
  const isOpen = parentComponent.state.open
  const willOpen = _isDrawer(action[G.UI].drawer, app)

  if (willOpen) {
    // console.warn('UI::UPDATE', willOpen, isOpen, actionComponent[G.CHILDREN][1][G.STATE])
    actionComponent[G.REF] && parentComponent.setState({
      open: true,
      ...action[G.UI].content || {},
      ...actionComponent[G.STATE],
      title: action[G.UI].title || '',
      children: [_clone(app[G.EVENTS])(actionComponent)],
    })
    !actionComponent[G.REF] && app[G.ADAPTER][G.UI][G.API].create(obj)
  } else if (isOpen && isOpen !== willOpen) {
    parentComponent.setState({
      open: false,
      ...actionComponent[G.STATE],
      children: [],
    })
  }
  return obj
}

/**
 * Update AppBar.
 *
 * Updates the AppBar content according to what is inside the current action's ui configuration.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Gaia.AppModule.Spec} fire and forget
 */
const updateAppBar = (app, obj) => {
  const { [G.ACTION]: action } = obj[G.STATE]
  const modal = _isModal(action[G.UI].modal, app)
  const drawer = _isDrawer(action[G.UI].drawer, app)
  // In case the action is not to be displayed as a modal, we need to always take into account what
  // is inside its ui configuration
  !modal && !drawer && app[G.REF].getAppBar().setState(action[G.UI])

  return obj
}

/**
 * Update SideBar.
 *
 * Updates the SideBar state according to what is inside the current action's ui configuration.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Gaia.AppModule.Spec} fire and forget
 */
const updateSideBar = (app, obj) => {
  const { [G.ACTION]: action } = obj[G.STATE]
  const modal = _isModal(action[G.UI].modal, app)
  const drawer = _isDrawer(action[G.UI].drawer, app)

  !modal && !drawer && app[G.REF].getSideBar().setState(action[G.UI])

  return obj
}

export default (rootGetter, app) => asyncpipe(
  curry(renderModuleInstance)(app),
  curry(updateContent)(app),
  curry(updateModal)(app),
  curry(updateDrawer)(app),
  curry(updateAppBar)(app),
  curry(updateSideBar)(app),
)
