/* global G */
import { getUrl, noLoaderMiddleware } from '@platform/adapter/note/helper'
import { getFirstItem } from 'lib/util'
import { defaultMiddleware } from 'platform/adapter/http/middleware'

const mapRefs = target => Object.keys(target).reduce((acc, key) => {
  const refValue = target[key]
  acc[key] = refValue.map(x => (typeof x === 'string' ? x : x.key))
  return acc
}, {})

/**
 * Attempts to update a note on the server. On success, it also updates it from the adapter's
 * data and then dispatches a G.DONE event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const item = detail[G.DATA]
  const hasAttachments = detail[G.ATTACHMENT]
  const callback = detail[G.FN]

  const adapter = app[G.ADAPTER][G.NOTE]
  const adapterData = adapter[G.DATA]

  const adapterItem = adapterData.find(x => x.key === item.key)

  const toBeNotified = [...item?.refs?.toBeNotified || [], ...adapterItem?.refs?.toBeNotified || []]

  const params = {
    key: adapterItem.key,
    _rev: adapterItem?._rev,
    value: {
      ...adapterItem.value,
      ...item.value,
    },
    refs: mapRefs({
      ...adapterItem.refs,
      toBeNotified,
    }),
  }

  // Initializing the loader if we have attachments
  hasAttachments && eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))

  const url = getUrl(app, { key: item.key })
  const result = await app[G.ADAPTER][G.HTTP][G.API].put({ url, params }, {
    middleware: hasAttachments
      ? noLoaderMiddleware
      : defaultMiddleware,
  })
  const data = getFirstItem(result)

  if (data.ok) {
    adapterItem._rev = data.rev
    adapterItem.value = {
      ...adapterItem.value,
      ...item.value,
    }

    adapterItem.refs = {
      ...adapterItem.refs,
      toBeNotified,
    }

    const done = await callback?.(adapterItem)

    // Closing the loader
    done && hasAttachments && eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

    eventBus.dispatch(eventBus.type(G.NOTE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
