/* global G */

/**
 * Check Connectivity
 *
 * Forces a connectivity check, which will put the application in offline mode
 * in case it couldn't ping the server.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {number} code               the error code
 * @param {string} [key=null]         the key of the document we couldn't find
 * @param {object} response           the server response
 * @returns {Promise<void>}
 */
export default async (obj, { code, key, response }) => {
  const eventBus = obj[G.EVENTS]

  eventBus.dispatch(eventBus.type(G.HTTP, G.UPDATE), {})
}
