/* eslint-disable max-len */
import set from '@platform/adapter/attachment/api/group/set'
import create from '@platform/adapter/attachment/api/group/create'
import purge from '@platform/adapter/attachment/api/group/purge'
import add from '@platform/adapter/attachment/api/group/add'
import insert from '@platform/adapter/attachment/api/group/insert'
import remove from '@platform/adapter/attachment/api/group/remove'
import apply from '@platform/adapter/attachment/api/group/apply'
import undo from '@platform/adapter/attachment/api/group/undo'
import destroy from '@platform/adapter/attachment/api/group/destroy'

/**
 * @memberOf Gaia.Adapter.Attachment.API
 * @namespace Group
 * @typedef Adapter.Attachment.API.Group
 *
 * @property {function} set - persist remote payload in G.CACHE
 * @property {function} add - persist file in G.DATA, it requires a set of options(ui props), passed
 *                            during call, and an optional FileList
 * @property {function} create - remote upload
 * @property {function} remove - remove persisted file from G.DATA or move file persisted in G.CACHE
 *                              to G.DELETE
 * @property {function} read - read from api end point, based on uuid of the entity
 * @property {function} purge - {@link delete} or {@link remove} file accordingly, using DELETE
 * @property {function} apply - {@link create} files persisted in G.DATA and {@link delete} files
 *                              persisted in G.DELETE
 * @property {function} undo - empty files persisted in G.DATA, move files persisted in G.DELETE to
 *                             G.CACHE
 * @param {Gaia.Web.Application} app  Platform Web Application
 * @param {string} groupName
 * @return {{}}
 */
const api = (app, groupName) => Object.create({}, {
  set: {
    value: set(app, groupName),
    iterable: true,
    enumerable: true,
  },
  add: {
    value: add(app, groupName),
    iterable: true,
    enumerable: true,
  },
  insert: {
    value: insert(app, groupName),
    iterable: true,
    enumerable: true,
  },
  create: {
    value: create(app, groupName),
    iterable: true,
    enumerable: true,
  },
  remove: {
    value: remove(app, groupName),
    iterable: true,
    enumerable: true,
  },
  purge: {
    value: purge(app, groupName),
    iterable: true,
    enumerable: true,
  },
  apply: {
    value: apply(app, groupName),
    iterable: true,
    enumerable: true,
  },
  undo: {
    value: undo(app, groupName),
    iterable: true,
    enumerable: true,
  },
  destroy: {
    value: destroy(app, groupName),
    iterable: true,
    enumerable: true,
  },
})

export default api
