/* global G */

/**
 * Forbidden Threshold Exceeded Dialog
 *
 * Displays a message informing the user that the upper threshold for an orgrefs update
 * has been exceeded.
 *
 * To be displayed if the server returns a status code of `403` and a `response.error`
 * of `THRESHOLD_2_EXCEEDED`.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.thresholdExceeded.title',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.thresholdExceeded.title',
        defaultValue: 'Sorry',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.thresholdExceeded.text',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.thresholdExceeded.text',
        defaultValue: 'You cannot change the organizational structure because a major rebuilding of indexes is required. Please contact Apprologic GmbH.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })
}
