/* eslint-disable no-unused-expressions,default-param-last */
import { useEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { PlatformEvent } from 'lib/util'

const initialState = { data: [], page: null, pending: true }

/**
 * Node that uses {@link IntersectionObserver} instances to trigger an {@param onIntersection}
 * callback. Whenever its view is intersected with the client's view, if it didn't trigger yet
 * since it was mounted, {@param onIntersection} is triggered and the observer disconnected,
 * rendering the element useless until it's mounted the next time.
 *
 * @param {string} id                             an identifier to be used as element key and passed
 *                                                as single parameter of {@param onIntersection}
 * @param {function(next: string)} onIntersection the function called the first time the element's
 *                                                view intersects with the client's view
 * @returns {JSX.Element}
 * @constructor
 */
const Observer = ({ id, onIntersection }) => {
  const ref = useRef()

  useEffect(() => {
    if (id) {
      const observer = new IntersectionObserver(([view]) => {
        if (view.isIntersecting) {
          observer.disconnect()
          onIntersection(id)
        }
      })
      observer.observe(ref.current)
      return () => observer?.disconnect()
    }
    return () => {}
  }, [])

  return (
    <li key={id} ref={ref} />
  )
}

const createObserver = (id, onIntersection) => (
  <Observer id={id} onIntersection={onIntersection}/>
)

/**
 * usePagination hook
 *
 * Uses {@param fn} to retrieve data, which is then passed inside the returned object's data
 * property. The data is retrieved in the following cases:
 * · The first time the element is mounted
 * · Whenever the returned observer is intersecting the client view
 * · Whenever {@param deps} changes
 *
 * The returned pending property is set to {@code true} before {@param fn} is called and to
 * {@code false} afterward.
 *
 * @param {function} fn                     a function to call in order to retrieve data
 * @param {Object[]|null} [startData=null]
 * @param {string|null} [nextPage=null]     the page to start from. if set, it won't try to retrieve
 *                                          data until the observer is intersecting
 * @param {string|null} [page=null]         the current page
 * @param {function} setPage                handler for changing the current page
 * @param {any[]} [deps=[]]                 dependencies array which makes {@param fn} be called
 *                                          whenever they change
 * @returns {{data: object[], pending: boolean, observer: React.ReactNode}}
 */
const usePagination = (fn, startData = null, nextPage = null, page, setPage, deps = []) => {
  const [state, setState] = useState(initialState)
  const [observer, setObserver] = useState(null)
  const { data, pending } = state

  const handle = (next) => {
    flushSync(() => {
      setObserver(null)
      setPage(next)
    })
  }

  useEffect(() => {
    setState(pending ? state : initialState);
    (async () => {
      try {
        if (startData && !page) {
          setObserver(nextPage ? createObserver(nextPage, handle) : null)
          setState({
            data: startData,
            page: nextPage,
            pending: false,
          })
        } else {
          const result = await fn(new PlatformEvent(new CustomEvent('open'), { page }))
          const [first] = result.data || result
          first?.key === -1 && throw TypeError()
          const next = result.page || null
          setObserver(next ? createObserver(next, handle) : null)
          setState({
            data: result.data || result,
            page: next,
            pending: false,
          })
        }
      } catch (e) {
        setObserver(null)
        setState(pending ? initialState : state)
      }
    })()
  }, [page, ...deps])

  return {
    data,
    page: state.page,
    pending,
    observer,
  }
}

export default usePagination
