/* global G */
import { withDependencyCheck } from 'lib/trait/with'
import PlatformError, { isPlatformError } from 'lib/util/error'

const descriptor = 'sequence::module::adapter::router::redirect'

// todo: check
// const abortMessage = 'redirect aborted. possible reasons for it:'
// + '\n - missing route configuration in clicked element'

const abortOnErrorMessage = 'redirect cancelled due to module containing an error'

const checkDeps = (obj, ...args) => {
  withDependencyCheck(descriptor, [G.ROUTER], obj[G.ADAPTER])
  withDependencyCheck(descriptor, [G.ROUTE], obj[G.STATE])
  return args
}

const onError = (obj, ...args) => {
  const state = obj[G.STATE]
  state[G.ROUTE] = state[G.ERROR] ? null : state[G.ROUTE]
  // throws "unreadable code detected" warning
  const error = new Error(abortOnErrorMessage)
  error.name = 'StateError'
  return !state[G.ERROR] ? args : throw PlatformError(obj, abortOnErrorMessage, error)
}

const redirect = obj => async (...args) => {
  checkDeps(obj)
  try {
    onError(obj)
    /** const { moduleAction, action } = [platform]/src/lib/adapter/router/api/index.js */
    const { moduleAction, action } = obj[G.ADAPTER][G.ROUTER]
    const route = obj[G.STATE][G.ROUTE]
    return route[G.MODULE]
      ? await moduleAction(route, ...args)
      : await action(route[G.ACTION], ...args)
  } catch (e) {
    // If we reached this point, we need to execute the router's `error` method if the error
    // is not a platform error. This means it hasn't been handled yet.
    const isAlreadyHandled = isPlatformError(e)
    if (!isAlreadyHandled) {
      await obj[G.ADAPTER][G.ROUTER].error(e)
    }

    // Regardless of the error type, we need to throw it here again to interrupt the application.
    // If we don't do this, we'll run into subsequent errors. However, if we simply throw an
    // {@link Error} here, and we indeed have a platform error, we basically obfuscate the error
    // type for the rest of the application, which will cause the error action to be called twice.
    // Once where we actually handled the error and once here after throwing an ordinary Error
    // even though we handled it already. So in case it's a platform error, just throw the error
    // itself. The part of the application that catches it will know not to do anything with it.
    throw !isAlreadyHandled
      ? Error(`${obj._name} ${descriptor} - ${e.message}`)
      : e
    // wip - selective error handling
    // on successful redirect, we get a message moduleAction() not found,
    // because the runtime of called action isn't finished yet, so the reference is lost.
    // this does not hinder the sequence to run successfully!
    // e.message.indexOf(abortOnErrorMessage) !== -1 && console.warn(e.message)
    //
    // if event handler executes a sequence instead of bound api,
    // we do could skip selective error handling
    // return e.message.indexOf('moduleAction') === -1
    //   && e.message.indexOf(abortOnErrorMessage) === -1
    //   ? throw Error(`${obj._name} ${descriptor} - ${e.message}`)
    //   : args
  }
}

/**
 * Module Redirect Sequence
 *
 * It uses provided adapter, G.ROUTER, in order to display different ui content,
 * be it an action of the same module, or different module/action combination.
 *
 */
export default redirect
