/* eslint-disable max-len */
import create from './create'
import deleteCart from './delete'
import cache from './cache'
import update from './update'
import set from './set'
import remove from './remove'
import read from './read'
import init from './init'
import activate from './activate'
import apply from './apply'
import add from './add'
import change from './change'

/**
 * CartEventListener
 *
 * @memberOf Gaia.Adapter.Cart.API
 * @callback CartEventListener
 * @param {Object} event                  the event object
 * @param {Object} event.detail           the event's detail object containing custom data
 * @param {File[]} event.detail[G.DATA]   the file/s to handle
 * @returns {void}
 */

/**
 * @memberOf Gaia.Adapter.Cart
 * @namespace API
 * @typedef Adapter.Cart.API
 *
 * @property {function} init      inits the users cart
 * @property {function} create    fetches the users current active cart from the server or creates
 *                                one if the user doesn't have one
 * @property {function} remove    removes an item from the current cart
 * @property {function} update    changes a property inside the current cart
 * @property {function} set       updates the server with the current cart
 * @property {function} cache     removes the current local cart
 * @property {function} read      returns the current local cart
 * @property {function} delete    deletes the current cart
 * @property {function} activate  sets the current users cart to draft mode and the incoming cart
 *                                to active
 * @property {function} apply     executes {@property set} with the current local cart
 * @property {function} add       adds an article to the users cart
 * @param {Gaia.Web.Application} app  Platform Web Application
 * @return {{}}
 */
const api = app => Object.create({}, {
  init: {
    value: init(app),
    iterable: true,
    enumerable: true,
  },
  create: {
    value: create(app),
    iterable: true,
    enumerable: true,
  },
  remove: {
    value: remove(app),
    iterable: true,
    enumerable: true,
  },
  update: {
    value: update(app),
    iterable: true,
    enumerable: true,
  },
  set: {
    value: set(app),
    iterable: true,
    enumerable: true,
  },
  cache: {
    value: cache(app),
    iterable: true,
    enumerable: true,
  },
  read: {
    value: read(app),
    iterable: true,
    enumerable: true,
  },
  delete: {
    value: deleteCart(app),
    iterable: true,
    enumerable: true,
  },
  activate: {
    value: activate(app),
    iterable: true,
    enumerable: true,
  },
  apply: {
    value: apply(app),
    iterable: true,
    enumerable: true,
  },
  add: {
    value: add(app),
    iterable: true,
    enumerable: true,
  },
  change: {
    value: change(app),
    iterable: true,
    enumerable: true,
  },
})

export default api
