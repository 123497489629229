/* eslint-disable object-curly-newline */
/* global G */

/**
 * Adds a loaded (from remote) attachment to `groupName`.
 *
 * Will dispatch `G.ATTACHMENT, G.DONE, groupName` afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail }) => {
  const bus = app[G.ADAPTER][G.EVENTS]
  const attachment = detail[G.DATA]

  const composedAttachment = attachment
    ? {
      ...attachment,
      // External attachments, like videos, don't have a key, they don't exist
      // as an attachment like we know it. So in that case, save its name as the
      // key so that we don't accidentally add it twice.
      key: attachment?.key || attachment?.value?.name,
    }
    : null

  const adapter = app[G.ADAPTER][G.ATTACHMENT]
  const group = adapter[G.GROUP][groupName]

  if (composedAttachment && group[G.DATA]) {
    const groupAttachment = group[G.DATA]?.length
      ? group[G.DATA].find(x => x.key === composedAttachment)
      : false

    !groupAttachment && (group[G.DATA] = group[G.DATA].concat(attachment))

    bus.dispatch(bus.type(G.ATTACHMENT, G.DONE, groupName),
      { [G.DATA]: group[G.DATA], [G.STATE]: group[G.STATE] })
  }
}

export default fn
