/* global G */
import { pipe, setKey } from 'lib/util'
import { withDescriptor, withObjectFreeze } from 'trait/with'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { hasNamespace } from 'trait/has'
import { usesNamespace, usesGetterSetter } from 'trait/uses'
import api from '@platform/adapter/attachment/api'
import Group from '@platform/adapter/attachment/group'

const descriptor = 'adapter:Attachment'

/**
 * Adapter Namespace
 *
 * Contains entries of native platform adapters
 *
 * @namespace Gaia.Adapter
 * @memberOf Gaia
 */
const defaults = {
  init: G.INIT,
  set: G.CACHE,
  read: G.READ,
  url: G.HTTP,
}

/**
 * Adds the default attachment group to the adapter {@code obj}.
 *
 * @param {Gaia.Web.Application} app the Web Platform Application
 * @return {function(*): *}
 */
const withDefaultGroup = app => (obj) => {
  const name = 'default'
  setKey(Group(app, name), name, obj[G.GROUP])
  return obj
}

/**
 * Attaches all api methods whose name can be found within {@link defaults} to the event handler by
 * assigning them an event type in the form:
 *
 * @example
 * eventBus.type(G.ATTACHMENT, G.INIT) === 'gaia:attachment:set'
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @return {(function(*): void)|*}
 */
const withEventHandlers = app => (obj) => {
  const eventBus = app[G.EVENTS]

  Object.keys(defaults).reduce((acc, key) => {
    const eventType = eventBus.type(G.ATTACHMENT, acc[key])
    const eventHandler = obj[G.API][key]
    eventBus.add(eventType, eventHandler)
    return acc
  }, defaults)

  return obj
}

/**
 * Native Platform Web Adapter Attachment
 *
 * Stores and manages data relative to either local or uploaded files in the application memory.
 *
 * Each adapter's namespace has the following use:
 *  - G.GROUP is used to keep track of {@link Gaia.Adapter.Attachment.Group}s of attachments
 *  - G.API contains the adapter's functionality
 *
 * @memberOf Gaia.Adapter
 * @namespace Attachment
 * @typedef Attachment
 *
 * @property {String} _name - module descriptor. see {@link withDescriptor}
 * @property {File[]} G.DATA - accumulator of list objects
 * @property {Object} G.API - adapter api
 * @property {Adapter.Attachment.API} API - adapter api revised
 *
 * @param {Gaia.Web.app} obj - web application
 * @return {Gaia.Adapter.Attachment} composition - Native Platform Web Adapter
 */
const adapterFn = obj => pipe(
  withDescriptor(descriptor),
  withGetterSetterFn(G.GROUP),
  usesGetterSetter(G.GROUP, {}),
  hasNamespace(G.API),
  usesNamespace(G.API, api(obj)),
  withEventHandlers(obj),
  withDefaultGroup(obj),
  withObjectFreeze,
)({})

export default adapterFn
