/* eslint-disable no-param-reassign */
/* global G */
import { withDescriptor, withObjectFreeze } from 'trait/with'
import { pipe } from 'lib/util'
import { withGetterSetterFn } from 'trait/with/GetterSetter'
import { hasNamespace } from 'trait/has'
import { usesGetterSetter, usesNamespace } from 'trait/uses'
import api from '@platform/adapter/attachment/api/group/index'

const descriptor = 'adapter:Attachment:Group'

export const defaults = {
  add: G.ADD,
  insert: G.INSERT,
  apply: G.APPLY,
  create: G.CREATE,
  destroy: G.DESTROY,
  purge: G.DELETE,
  remove: G.REMOVE,
  set: G.CACHE,
  undo: G.UNDO,
}

/**
 * Attaches all api methods whose name can be found within {@link defaults} to the event handler by
 * assigning them an event type in the form:
 *
 * @example
 * const groupName = 'default'
 * eventBus.type(G.ATTACHMENT, G.CREATE, groupName) === 'gaia:attachment:create:default'
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @return {(function(*): void)|*}
 */
const withEventHandlers = app => (obj) => {
  const eventBus = app[G.EVENTS]
  const name = obj[G.REF]

  Object.keys(defaults).reduce((acc, key) => {
    const eventType = eventBus.type(G.ATTACHMENT, acc[key], name)
    const eventHandler = obj[G.API][key]
    eventBus.add(eventType, eventHandler)
    return acc
  }, defaults)

  return obj
}

/**
 * Adds a {@code destroy()} function to {@code obj} that removes all event handlers attached to
 * their methods through {@link withEventHandlers} and also makes it remove itself from the
 * adapter's list of groups.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @return {(function(*): void)|*}
 */
const withDestructor = app => (obj) => {
  const eventBus = app[G.EVENTS]
  const name = obj[G.REF]

  Object.defineProperty(obj, 'destroy', {
    value: () => {
      Object.keys(defaults).reduce((acc, key) => {
        const eventType = eventBus.type(G.ATTACHMENT, acc[key], name)
        eventBus.remove(eventType, obj[G.API][key])
        return acc
      }, defaults)
      const adapter = app[G.ADAPTER][G.ATTACHMENT]
      const group = adapter[G.GROUP][name]
      const groupData = group[G.DATA] || []

      groupData.forEach((attachment) => {
        URL.revokeObjectURL(attachment.url)
      })

      delete adapter[G.GROUP][name]
    },
    enumerable: true,
  })

  return obj
}

/**
 * Native Platform Web Adapter Attachment Group
 *
 * Stores and manages data relative to either local or uploaded files in the application memory.
 *
 * Each group's namespace has the following use:
 *  - G.REF contains the group's name
 *  - G.CACHE is used to keep track of files that have already been uploaded to the server
 *  - G.DATA is used to keep track of local files
 *  - G.DELETE is used to keep track of files to be deleted
 *  - G.API contains the group's functionality
 *
 * Initializing a group object also attaches its API methods to the eventBus, as described in
 * {@link withEventHandlers}. For them to be removed, it is necessary to call {@code destroy()}, as
 * explained in {@link withDestructor}.
 *
 * @memberOf Gaia.Adapter.Attachment
 * @typedef AttachmentGroup
 * @namespace Group
 *
 * @property {String} _name       module descriptor. see {@link withDescriptor}
 * @property {File[]} G.DATA      accumulator of (local) attachments
 * @property {Object[]} G.CACHE   accumulator of (remote) attachments
 * @property {Object[]} G.DELETE  accumulator of attachments to be deleted
 */

/**
 * Attachment Group
 *
 * @param {Gaia.Web.Application} obj        the Web Platform Application
 * @param {String} name                     the group identifier
 * @param {Object} options                  initial options for the attachment group
 * @param {Boolean} options.cache           whether the group should hold remote attachments
 * @return {Gaia.Adapter.Attachment.Group}  a new attachment group composition
 */
export default (obj, name, options = {}) => pipe(
  withDescriptor(`${descriptor}:${name}`),
  withGetterSetterFn(G.REF),
  usesGetterSetter(G.REF, name),
  withGetterSetterFn(G.DATA),
  usesGetterSetter(G.DATA, []),
  withGetterSetterFn(G.CACHE),
  usesGetterSetter(G.CACHE, []),
  withGetterSetterFn(G.DELETE),
  usesGetterSetter(G.DELETE, []),
  withGetterSetterFn(G.EVENTS),
  usesGetterSetter(G.EVENTS, []),
  withGetterSetterFn(G.STATE),
  usesGetterSetter(G.STATE, options),
  hasNamespace(G.API),
  usesNamespace(G.API, api(obj, name)),
  withEventHandlers(obj),
  withDestructor(obj),
  withObjectFreeze,
)({})
