/* global G */
import { getUrl } from '@platform/adapter/message/helper'
import { getFirstItem } from 'lib/util'

/**
 * Attempts to delete a message from the server. On success, it also removes it from the adapter's
 * data and then dispatches a G.DONE event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapter = app[G.ADAPTER][G.MESSAGE]
  const adapterData = adapter[G.DATA]
  const { key } = detail[G.DATA]

  const url = getUrl(app, { key })
  const message = adapterData.find(item => item.key === key)

  const response = await app[G.ADAPTER][G.HTTP][G.API].patch({ url,
    params: {
      _rev: message._rev,
      key,
      value: {
        status: 90,
        text: '',
        deleteTimestamp: new Date().toISOString(),
      },
    } })
  const result = getFirstItem(response)

  if (result.ok) {
    message.value.text = null
    message.value.status = 90
    message.value.attachments = []

    eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
