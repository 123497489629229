/* global G */
import { pipe } from 'lib/util'
import { withDescriptor, withObjectFreeze } from 'trait/with'
import { hasNamespace } from 'trait/has'
import { usesGetterSetter, usesNamespace } from 'trait/uses'
import { withGetterSetterFn } from 'trait/with/GetterSetter'
import api from './api'

const descriptor = 'adapter:Cart'

/**
 * Adapter Namespace
 *
 * Contains entries of native platform adapters
 *
 * @namespace Gaia.Adapter
 * @memberOf Gaia
 */

/**
 * Native Platform Web Adapter Cart
 *
 * Retrieves and manages the active cart for the user
 *
 * @memberOf Gaia.Adapter
 * @namespace Cart
 * @typedef Cart
 *
 * @property {String} _name                 module descriptor. see {@link withDescriptor}
 * @property {File[]} G.DATA                the current active cart
 * @property {Object} G.API                 adapter api
 * @property {Adapter.Attachment.API} API   adapter api revised
 *
 * @param {Gaia.Web.app} obj                web application
 * @return {Gaia.Adapter.Cart}              composition - Native Platform Web Adapter
 */
const adapterFn = (obj) => {
  const adapter = pipe(
    withDescriptor(descriptor),
    withGetterSetterFn(G.STATE),
    usesGetterSetter(G.STATE, {}),
    withGetterSetterFn(G.DATA),
    usesGetterSetter(G.DATA, null),
    hasNamespace(G.API),
    usesNamespace(G.API, api(obj)),
    withObjectFreeze,
  )({})

  /**
   * @type {Gaia.Adapter.EventBus}
   */
  const eventBus = obj[G.EVENTS]

  const defaults = {
    init: G.INIT,
    create: G.CREATE,
    remove: G.REMOVE,
    set: G.SET,
    update: G.UPDATE,
    cache: G.CACHE,
    read: G.READ,
    delete: G.DELETE,
    activate: G.ACTIVATE,
    apply: G.APPLY,
    change: G.CHANGE,
    add: G.ADD,
  }

  Object.keys(defaults).reduce(
    (acc, key) => eventBus.add(eventBus.type(G.CART, acc[key]), adapter[G.API][key]) || acc,
    defaults,
  )

  return adapter
}

export default adapterFn
