/* eslint-disable no-restricted-globals,no-unused-vars */
/* global G */
import { useCallback, useContext, useEffect, useRef, useState, useTransition } from 'react'
import ApplicationContext from '@platform/react/context/application'
import useEventHandler from '@platform/react/hook/useEventHandler'
import useEventCallback from '@platform/react/hook/useEventCallback'

const initialState = {
  progress: 0,
  loading: true,
  placeholder: false,
  src: null,
}

/**
 * Helper function to get the attachments of `group`.
 *
 * @param {string} group                    attachment group
 * @param {Gaia.Adapter.EventBus} eventBus  event adapter
 * @returns {Promise<Object[]>}
 * @private
 */
const _getGroupAttachments = async (group, eventBus) => await new Promise((resolve) => {
  eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE, group), ({ detail }) => {
    resolve(detail[G.DATA] || [])
  })
  eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CACHE, group), { [G.DATA]: {} })
})

/**
 * Attachment API Hook
 *
 * automates usage of attachment api
 *
 * @param {Object} options                hook options
 * @param {Attachment} options.attachment attachment doc
 * @param {boolean} [options.initial]     whether or not to fetch the attachment right away
 * @return {{onLoad: (function(): void), state: {src: null, progress: number, loading: boolean}}}
 */
const useAttachmentAPI = ({ attachment, group = null, initial = true }) => {
  const { eventBus } = useContext(ApplicationContext)
  const [id, setId] = useState(attachment?.key || null)
  const [read, setRead] = useState(initial)
  const [, startTransition] = useTransition()
  const [state, setState] = useState(initialState)
  const { loading } = state

  /**
   * List of already fetched attachments. We need to use {@link useRef} here instead of
   * {@link useState}, because we need to clean up residual object URLs once the component unmounts.
   * If we were to use {@link useState}, the state would already have been cleared once we trigger
   * the cleanup function, resulting in a memory leak.
   */
  const loadedAttachments = useRef([])
  const { key, uuid = key } = attachment || {}

  // If initial changes from the outside, set read accordingly
  useEffect(() => { setRead(initial) }, [initial])

  /**
   * We may maintain multiple attachments for one instance of this component. This means that
   * {@link attachment} may change during runtime. The {@link id} indicates which attachment we
   * are currently displaying. We only change the current {@link id} if the incoming attachment
   * changed.
   */
  useEffect(() => {
    const newId = attachment?.key || null
    id !== newId && startTransition(() => {
      setId(newId)
      setState(initialState)
      setRead(initial)
    })
  }, [attachment])

  useEffect(() => {
    let active = true
    if (!read && !loading) { return () => {} }

    if (read) {
      (async () => {
        const groupAttachments = group ? await _getGroupAttachments(group, eventBus) : []
        const alreadyLoadedAttachment = loadedAttachments.current.find(x => x.key === id) || null
        const alreadyLoadedGroupAttachment = groupAttachments.find(x => x.key === id && x.url) || null

        if (alreadyLoadedGroupAttachment) {
          setState(prevState => ({
            ...prevState,
            src: alreadyLoadedGroupAttachment.url || prevState.src,
            loading: false,
            placeholder: false,
          }))
        } else if (alreadyLoadedAttachment) {
          startTransition(() => setState(alreadyLoadedAttachment || alreadyLoadedGroupAttachment))
        } else {
          attachment?.value?.name && eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.READ), {
            [G.DATA]: group ? { ...attachment, group } : attachment,
          })
        }
      })()
    }

    return () => { active = false }
  }, [read, id])

  // Cleaning up all object URLs when the component unmounts
  useEffect(() => () => loadedAttachments.current.forEach((x) => {
    !group && URL.revokeObjectURL(x.src)
  }), [])

  const _progressEventName = eventBus.type(G.ATTACHMENT, G.READ, key)
  const _progressEventHandler = useEventCallback(({ detail }) => {
    const { progress, status } = detail
    detail.uuid === uuid && setState(prevState => ({
      ...prevState,
      loading: progress !== 100 && status === 200,
      progress,
    }))
  })
  useEventHandler(eventBus, _progressEventName, _progressEventHandler)

  const _doneEventName = eventBus.type(G.ATTACHMENT, G.DONE, key)
  const _doneEventHandler = useEventCallback(({ detail }) => {
    const { url } = detail
    detail.uuid === uuid && setState((prevState) => {
      const newState = {
        ...prevState,
        src: url || prevState.src,
        placeholder: false,
        loading: false,
      }

      // Only locally manage the attachments if we don't have a group otherwise we may call
      // `revokeObjectURL` on an attachment that's part of a group
      !group && (
        loadedAttachments.current = [...loadedAttachments.current, { ...newState, key: detail.key }]
      )

      return newState
    })
  })
  useEventHandler(eventBus, _doneEventName, _doneEventHandler)

  const onLoad = useCallback(() => {
    eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CACHE, key))
  }, [])

  const fetchAttachment = () => { setRead(true) }

  return { state, onLoad, fetch: fetchAttachment }
}

export default useAttachmentAPI
