/* eslint-disable implicit-arrow-linebreak,no-promise-executor-return,arrow-body-style */
export const def = x => typeof x !== 'undefined'
export const isFn = x => typeof x === 'function'
export const isNum = x => typeof x === 'number'
export const isBool = x => typeof x === 'boolean'
export const isStr = x => def(x) && x.constructor === String
export const isObj = x => def(x) && x.constructor === Object
export const isArr = x => def(x) && x.constructor === Array
export const isFloat = x => def(x) && !!(x % 1)
// export const isArr = x => def(x) && Array.isArray(x)
// export const isStr = x => def(x) && Object.prototype.toString.call(x) === '[object String]'

/**
 * If {@param value} is an array, returns its first item, otherwise returns {@param value}.
 */
export const getFirstItem = value => (value && isArr(value) ? value[0] : value) || null

/**
 * Currying by using Variadic Functions.
 *
 * Empty Accumulator Invocation is being disabled by Error
 *
 * ie:
 *
 * const sum3 = curry((x, y, z) => x + y + z)
 * sum3(1,2,3) // 6
 * sum3(1)(2)(3) // 6
 * //sum3()()()(1,2,3) // 6 - empty accumulator invocation is disabled
 * sum3(1)(2,3) // 6
 * //sum3()()()(1)()()(2,3) // 6 - empty accumulator invocation is disabled
 *
 * src: https://hackernoon.com/currying-in-js-d9ddc64f162e
 *
 * @param fn
 * @returns {Function}
 */
export function curry(fn) {
  // console.debug('curry fn:', fn)

  return (...xs) => {
    // console.debug('curry xs:', ...xs)

    if (xs.length === 0) {
      throw Error('EMPTY INVOCATION')
    }

    if (xs.length >= fn.length) {
      return fn(...xs)
    }

    return curry(fn.bind(null, ...xs))
  }
}

/**
 * Functional Composition.
 *
 * Function composition is a mathematical concept that allows you to combine
 * two or more functions into a new function.
 *
 * important: if any of the composed functions use currying,
 * make sure they are at the TOP of the combine()
 * otherwise, the trailing functions will receive a function as parameter,
 * instead of the expected type.
 *
 * RULE OF THE THUMB: ORDER MATTERS
 *
 * A key to function composition is having functions that are composable.
 * A composable function should have 1 input argument and 1 output value.
 * That output value can be used as an input argument of the next composable function.
 * You can turn any function into a composable function by currying the function.
 *
 * src: https://hackernoon.com/javascript-functional-composition-for-every-day-use-22421ef65a10
 *
 * @param functions
 * @returns {function(*=): *}
 */
export const compose = (...functions) => data => functions.reduceRight(
  (value, func) => func(value),
  data,
)

/**
 * Functional Composition in Reverse Order.
 *
 * important: if any of the composed functions use currying,
 * make sure they are at the BOTTOM of the pipe()
 * otherwise, the trailing functions will receive a function as parameter,
 * instead of the expected type.
 *
 * RULE OF THE THUMB: ORDER MATTERS
 * src: https://hackernoon.com/javascript-functional-composition-for-every-day-use-22421ef65a10
 *
 * @param functions
 * @returns {function(*=): *}
 */
export const pipe = (...functions) => data => functions.reduce(
  (value, func) => func(value),
  data,
)

/**
 * Wraps piped functions with Promise.
 *
 * @param functions
 * @return {function(*=): *}
 */
export const asyncpipe = (...functions) => param => functions.reduce(
  async (result, next) => next(await result),
  param,
)

/**
 * Async Composition in Reverse Order with Spread Parameters
 *
 * Used to pipe async functions with N parameters
 *
 * @param fns
 * @return {function(...[*]=): *}
 */
export const asyncPipeSpread = (...fns) => async (...args) => fns.reduce(async (result, fn) => {
  return fn(...(await result).flat())
}, args)

/**
 * Executes {@link asyncPipeSpread} for the functions passed as fns only if the result of executing
 * {@param condition} is truthy. Otherwise, returns args.
 *
 * @param {function} condition  a function whose result decides whether fns are executed
 * @returns {function(...[*]): function(...[*]): Promise<*|*[]>}
 */
export const asyncPipeSpreadIf = condition => (...fns) => async (...args) => (condition(...args)
  ? await asyncPipeSpread(...fns)(...args)
  : args)

/**
 * Connection Bottleneck Emulation.
 *
 * provides mocked slow connection speeds,
 * to emulate potential bottlenecks in communication with backend
 *
 * @param stallTime
 * @return {Promise<void>}
 */
export async function stall(stallTime = 3000) {
  await new Promise(resolve => setTimeout(resolve, stallTime))
}

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * src: https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 *
 * @param {...object} objects - Objects to merge
 * @returns {Object} New object with merged key/values
 */
export const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === 'object'
  return objects.reduce((prev, obj) => {
    const mergeResult = prev
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key]
      const oVal = obj[key]
      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        mergeResult[key] = [...pVal, ...oVal]
          .filter((element, index, array) => array.indexOf(element) === index)
      } else if (isObject(pVal) && isObject(oVal)) {
        mergeResult[key] = mergeDeep(pVal, oVal)
      } else {
        mergeResult[key] = oVal
      }
    })
    return mergeResult
  }, {})
}

/**
 * Async Curry.
 *
 * performs currying using parallel pattern
 *
 * src: https://github.com/LAJW/curry-compose/blob/master/curry-compose.js
 *
 * @attention
 * usage of asyncCurry with spread operator is not supported
 * todo: refactor to ES6
 *
 * @param fn
 * @return {function(): Function}
 */
export function asyncCurry(fn) {
  return () => {
    // eslint-disable-next-line prefer-rest-params
    const args = Array.prototype.slice.call(arguments)
    // eslint-disable-next-line func-names
    return function (value) {
      args.push(value)
      // eslint-disable-next-line no-restricted-syntax
      for (const arg of args) {
        if (arg instanceof Promise) {
          // eslint-disable-next-line no-shadow,prefer-spread
          return Promise.all(args).then(args => fn.apply(null, args))
        }
      }
      // eslint-disable-next-line prefer-spread
      return fn.apply(null, args)
    }
  }
}

/**
 * Async version of the {@link Array.some} method.
 *
 * @param {array} arr           array to iterate
 * @param {function} predicate  called for every element of {@param arr} until it returns true
 * @returns {Promise<boolean>}  true if {@param predicate} returns true for any element of
 *                              {@param arr}
 * @private
 */
export const _asyncSome = async (arr, predicate) => {
  for (let x = 0; x < arr.length; x++) {
    // eslint-disable-next-line no-await-in-loop
    if (await predicate(arr[x], x, arr)) return true
  }
  return false
}

/**
 * Sets symbol in an object, given the key is not a Namespace
 *
 * symbol can be either string or Symbol
 *
 * @type {function(...[*]=)}
 */
export const setKey = curry((value, symbol, obj) => {
  // eslint-disable-next-line no-param-reassign
  obj[symbol] = value
  return obj
})

/**
 * Reads symbol key content from an object
 *
 * @type {function(...[*]=)}
 */
export const getKey = curry((symbol, obj) => obj && obj[symbol])

/**
 * Deletes symbol in object
 * If symbol exists
 *
 * @type {function(...[*]): (*)}
 */
export const deleteKey = curry((symbol, obj) => {
  if (obj[symbol]) {
    // eslint-disable-next-line no-param-reassign
    delete obj[symbol]
  }
  return obj
})

/**
 * Calls {@param fn} {@param count} times and returns all the results in an array.
 *
 * @typedef {*} T
 * @param {number} count      the number of times to call {@param fn}
 * @param {(number) => T} fn  the function to call {@param count} times
 * @returns {T[]}             an array containing the results of the calls
 */
export const repeat = (count, fn) => {
  // Array.from({ length: count }, (e, i) => fn(i))
  const result = []
  for (let x = 0; x < count; x++) {
    result.push(fn(x))
  }
  return result
}

/**
 * Executes action for each element.
 *
 * @param {function} action
 * @returns {function(...[*]): unknown[]}
 */
export const bulk = action => (...elements) => elements.map(element => element && action(element))

/**
 * Simple debounce function.
 *
 * @param {Function} func           the function to debounce
 * @param {number} [timeout]        the debouncing period
 * @param {boolean} [leading]       whether or not use leading debounce
 * @returns {(function(): void)|*}
 */
export const debounce = (func, timeout = 300, leading = false) => {
  let timer
  return (...args) => {
    leading && !timer && func(...args)

    clearTimeout(timer)
    timer = setTimeout(() => {
      leading
        ? (timer = undefined)
        : func(...args)
    }, timeout)
  }
}

/**
 * Simple throttling function.
 *
 * @param {Function} func     the function to throttle
 * @param {number} [timeout]  the throttling period
 * @returns {function(...[*]): *}
 */
export const throttle = (func, timeout = 300) => {
  let lastCalled = 0
  return (...args) => {
    const now = new Date().getTime()
    if (now - lastCalled < timeout) {
      return null
    }
    lastCalled = now
    return func(...args)
  }
}

/**
 * Simple waiting function
 *
 * @param {number} ms time in ms to wait before returning
 * @returns {Promise<unknown>}
 */
export const wait = ms => new Promise((resolve) => { setTimeout(resolve, ms) })

/**
 * Simple polling function
 * @param {Function} func       function to call in each iteration
 * @param {Function} predicate  predicate to check in each iteration
 * @param {number} timeout      time in ms to wait between each iteration
 * @param {number} limit        maximum number of tries before returning
 * @returns {Promise<*>}
 */
export const poll = async (func, predicate, timeout = 1000, limit = 10) => {
  try {
    let requestLimit = limit
    let result = await func()

    while (!predicate(result) && requestLimit > 1) {
      requestLimit--
      await wait(timeout)
      result = await func()
    }

    return result
  } catch (e) {
    console.error(e)

    return null
  }
}

/**
 * Helper function to replace {@link React.Children.map}.
 *
 * @param {Object|Object[]} children  the children to reduce
 * @returns {*}
 */
export const arrayOf = children => (
  children
    ? (isArr(children) && children) || [children]
    : []
)

/**
 * Gets {@param x} from the {@param theme}'s palette.
 *
 * @param {Object} theme        the theme
 * @param {String} x            the color to get
 * @param {boolean} [important] whether the color style should be marked with !important
 *
 * @return {String}
 */
export const getThemeColor = (theme, x, important) => {
  if (!x || x.includes('#')) return x

  const [color, variant] = x.includes('.')
    ? x.split('.') // text.primary
    : x.split(/(?=[A-Z])/).map(t => t.toLowerCase()) // textPrimary

  return `${theme.palette[color]?.[variant || 'main']}${important ? '!important' : ''}`
}

/**
 * Maps module names to translation file names, so that we can group the translations of some
 * modules together.
 *
 * @type {Object}   a map object with entries module name => translation file name
 */
export const translationsMap = {
  request: 'ticket',
  support: 'guest',
  registration: 'guest',
  serviceItem: 'device',
  dashboard: 'common',
  error: 'common',
  textTemplate: 'ticket',
  feedback: 'user',
  camera: 'common',
}

export PlatformEvent from './event'
