/* global G */

/**
 * Returns the default route for the given user role.
 *
 * @param {Gaia.Web.Application} obj the Web Platform Application
 * @returns {Object}
 * @private
 */
const _getDefaultRoute = (obj) => {
  const meta = obj[G.SESSION][G.STATE][G.META]
  // `contextRoles` will be the available roles for the chosen context
  // 'userRole' will be the users role
  const { role: userRole, roles: contextRoles = [] } = meta || {}

  const { defaults } = obj[G.CONFIGURATION]
  const { route: defaultRoute, routes } = defaults
  let entry

  // Concatenate the user role to the list of context roles
  // So that context roles take precedence
  const availableRoles = userRole?.key
    ? [...contextRoles, userRole.key]
    : contextRoles

  // For each of the current context's roles...
  availableRoles.some((role) => {
    // ...look for a default route that contains it in its role array
    entry = routes.find(item => item.role.includes(role))
    // if a route is found, break the loop
    return entry
  })

  return entry?.route || defaultRoute
}

export default _getDefaultRoute
