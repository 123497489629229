/* global G */

/**
 * Forbidden Org Refs Update Running Dialog
 *
 * Displays a message informing the user that another update to the organisational
 * structure is currently running.
 *
 * To be displayed if the server returns a status code of `403` and a `response.error`
 * of `ORGREFS_UPDATE_RUNNING`.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.orgRefsUpdateRunning.title',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.orgRefsUpdateRunning.title',
        defaultValue: 'Sorry',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.forbidden.orgRefsUpdateRunning.text',
      {
        ns: 'common',
        _key: 'dialog.error.forbidden.orgRefsUpdateRunning.text',
        defaultValue: 'You cannot change the organizational structure at the moment because another update is still running. Please repeat the change at a later time.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })
}
