/* global G */
import { curry } from 'lib/util'
import settings from '@tenant/settings'
import HttpError from 'platform/adapter/http/error'

/**
 * Persistence middleware handler.
 *
 * Checks whether the application has internet connection and handles the caching of data
 * accordingly.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {function} next             the next middleware function
 * @param {Object} args               the request's arguments
 * @return {Object}                   the response object
 */
export default curry(async (obj, next, args) => {
  const online = obj[G.ADAPTER][G.SESSION][G.STATE][G.ONLINE]
  const storage = obj[G.ADAPTER][G.PERSISTENCE][G.API]
  let result

  if (settings.suppressPersistence) {
    result = await next(args) // if setting is not enabled, simply let the request go through
  } else if (online) {
    const { url, method, params: payload } = args // obtain request data
    result = await next(args) // if application is online, call next middleware, no try-catch
    try {
      // persist request and response
      await storage.putRequest({ url, method, payload, data: result })
    } catch (error) {
      console.warn(`Unable to persist ${method} request with url: ${url}.`, error)
    }
  } else {
    const { url, method, params: payload } = args // obtain request data
    // if application is offline, don't call next middleware
    try {
      result = await storage.getRequest({ url, method, payload }) // obtain persisted response
    } catch (error) {
      console.warn(`Unable to obtain persisted ${method} request by url: ${url}`, error)
      throw new HttpError('Not found', 404)
    }
  }
  return result
})
