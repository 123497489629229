/* global G */

/**
 * Updates a single message in the adapter. Dispatches `G.MESSAGE, G.DONE`
 * afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => ({ detail }) => {
  const { [G.DATA]: item } = detail
  const { [G.EVENTS]: eventBus, [G.MESSAGE]: adapter } = app[G.ADAPTER]
  const adapterData = adapter[G.DATA]

  const adapterItem = adapterData.find(dataItem => dataItem.key === item.key)
  adapterItem._rev = item._rev
  adapterItem.value = item.value

  eventBus.dispatch(
    eventBus.type(G.MESSAGE, G.DONE),
    { [G.DATA]: adapterData },
  )
}

export default fn
