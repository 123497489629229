import impersonation from './impersonation'
import loader from './loader'
import error from './error'
import invite from './invite'
import language from './language'
import persistence from './persistence'
import context from './context'
import sentry from './sentry'

export const defaultMiddleware = [
  loader,
  persistence,
  language,
  context,
  error,
  sentry,
  impersonation,
]

/**
 * Middleware.
 *
 * Used to intercept requests and responses originated by the {@link Gaia.Adapter.Http}.
 *
 * A middleware function must call {@param next} with {@param args} before, after or between its
 * own code so that the next middleware functions and the HTTP request are executed.
 *
 * @memberOf Gaia.Adapter.Http
 * @typedef {function} Http.Middleware
 * @param {Gaia.Web.Application} obj          the Web platform Application
 * @param {Gaia.Adapter.Http.Middleware} next the next middleware function to be called with
 *                                            {@param args}
 * @param {Object} args                       the request's arguments
 */
export default {
  loader,
  persistence,
  language,
  context,
  error,
  sentry,
  impersonation,
  invite,
}
