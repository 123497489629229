/**
 * Validator - Min Length
 *
 * @param {string} value - value to validate
 * @param {Object} options - validator options
 * @return {string} value - validated value
 */
export default (value, options) => {
  const { min, error } = options
  return [...value].length >= min ? value : throw TypeError(`${error} ${min}`)
}
