/* global G */
import { actionWithHooks } from 'lib/sequence/module/action'

const descriptor = Symbol('hook::module::action').toString()

/**
 * Module Action Hook.
 *
 * Executes {@link actionWithHooks} with {@param action}.
 *
 * @param {string} name   the action to execute
 * @return {function(...[*]): *[]}
 */
const action = name => obj => async (...args) => {
  try {
    await actionWithHooks(obj[G.ACTIONS][name])(...args)
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

/**
 * Module Current Action Hook
 *
 * Executes {@param obj[G.STATE][G.ACTION]}
 *
 * @param {Object} obj  the current module composition object
 * @returns {function(...[*]): Promise<*[]>}
 */
export const currentAction = obj => async (...args) => {
  try {
    await actionWithHooks(obj[G.ACTIONS][obj[G.STATE][G.ACTION]._name])([])
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default action
