/* global G */

/**
 * Dispatches G.DONE event with the current set of messages.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => () => {
  const { [G.EVENTS]: eventBus, [G.MESSAGE]: adapter } = app[G.ADAPTER]

  eventBus.dispatch(
    eventBus.type(G.MESSAGE, G.DONE),
    { [G.DATA]: adapter[G.DATA] },
  )
}

export default fn
