/* eslint-disable import/prefer-default-export,object-curly-newline,default-param-last */
/* global G */
import { API_URL } from 'lib/util/environment'

// create worker on the fly

export const createWorker = (fn) => {
  const blob = new Blob([`(${fn})()`])
  const url = URL.createObjectURL(blob)
  return {
    source: url,
    worker: new Worker(url),
  }
}

/**
 * Returns the parts of the URL to be used to generate the attachments URL. For that, it makes use
 * of the current model's api values, which can be overridden with the optional {@code props}
 * object.
 *
 * Current attachment URLs:
 *
 * Named (e.g. avatar)
 * POST    /v1/<type>/<parentId>/attachment/<attachmentName>
 * GET     /v1/<type>/<parentId>/attachment/<attachmentName>
 * DELETE  /v1/<type>/<parentId>/attachment/<attachmentName>
 *
 * Unnamed (UUID)
 * POST    /v1/<type>/<parentId>/attachment
 * GET     /v1/attachment/<attachmentID>
 * DELETE  /v1/attachment/<attachmentID>
 *
 * @param {Gaia.Web.Application} app    the Platform Web Application
 * @param {Object} [overrides]          an optional object, in case we want to override the current
 *                                      model values to form the URL
 * @param {string} name                 attachment name
 * @returns {{ref, host, api, version}} the URL parts
 */
const getUrlParts = (app, overrides = {}, name = 'unknown') => {
  const appState = app[G.SESSION][G.STATE]
  const host = appState[G.HTTP] || API_URL
  const model = appState[G.MODULE][G.MODEL]
  const version = overrides.version || model[G.PROPS].version
  const api = overrides.api || model[G.PROPS].api
  const ref = overrides.ref || model[G.STATE][G.REF]
  const urlParts = {
    host,
    version,
    api,
    // If we have no name (e.g. ''), we don't need a ref, let's not error then
    ...name !== '' ? { ref } : {},
  }
  const part = Object.keys(urlParts).find(key => !urlParts[key])
  // eslint-disable-next-line no-unused-expressions
  part && throw ReferenceError(`${part} is required`)
  return urlParts
}

/**
 * Returns the URL used to create new attachments.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {Object} [overrides]        an optional object, in case we want to override the current
 *                                    model values to form the URL
 * @returns {string}                  the attachments URL
 */
export const getCreateUrl = (app, overrides) => {
  const { host, version, api, ref } = getUrlParts(app, overrides)
  return `${host}/api/v${version}/${api}/${ref}/attachment`
}

/**
 * Returns the draft URL for attachments.
 *
 * @param app
 * @param overrides
 * @returns {`${string}/?draft=true`}
 */
export const getDraftUrl = (app, overrides) => `${getCreateUrl(app, overrides)}/?draft=true`

/**
 * Returns the URL used to read existing remote attachments.
 *
 * Depending on whether the attachment upload has a name (i.e. is a named one), uses an url of a
 * different one.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {Object} [props]            an optional object, in case we want to override the current
 *                                    model values to form the URL
 * @param {string} [key]              the id of the unnamed attachment
 * @param {string} [name]             the name of the named attachment
 * @returns {string}                  the attachments URL
 */
export const getReadUrl = (app, props, key, name = '') => {
  const { host, version, api, ref } = getUrlParts(app, props, name)
  return name
    ? `${host}/api/v${version}/${api}/${ref}/attachment/${name}`
    : `${host}/api/v${version}/attachment/${key}`
}

/**
 * Returns the URL used to delete existing remote attachments.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {string}                  the attachments URL
 */
export const getDeleteUrl = (app) => {
  const appState = app[G.SESSION][G.STATE]
  const host = appState[G.HTTP]
  return `${host}/api/v1/attachment`
}

export const terminate = (worker, url = null, group = null) => () => {
  worker.terminate()
  url && !group && URL.revokeObjectURL(url)
}

/**
 * Checks if attachment is remote
 *
 * @param attachment - attachment document, local or remote
 * @return {boolean}
 */
export const isRemote = ({ key, value }) => key !== value.name

/**
 * Disables the loader middleware
 *
 * @param {Function} loader   loader middleware
 * @param {object} middleware other middlewares
 * @returns {unknown[]}
 */
export const noLoaderMiddleware = ({ loader, ...middleware }) => Object.values(middleware)
