/* eslint-disable no-param-reassign */
/* global G */

import { def, isArr } from 'lib/util'

/**
 * Sets an item on the adapters {@code G.DATA} by either adding a new
 * value or updating an existing one.
 *
 * Calls the {@code update} method afterward to update the remote
 * document.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {(function({key: *, value: *, ns?: null}): Promise<*|undefined>)|*}
 */
const fn = app => async ({ key, value, ns = null }) => {
  const settings = app[G.ADAPTER][G.SETTINGS][G.DATA]

  const target = ns ? settings[ns] : settings

  if (target) {
    if (isArr(target)) {
      const alreadyAdded = target.findIndex(x => x?.key === key)
      alreadyAdded !== -1
        ? target[alreadyAdded] = { key, ...value }
        : target.push({ key, ...value })
    } else {
      target[key] = value
    }

    return await app[G.ADAPTER][G.SETTINGS][G.API].apply()
  }
}

export default fn
