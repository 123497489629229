/* global G */
import componentComposition from 'trait/composition/component'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'
import { hasNamespace } from 'trait/has'
import hasNamespaceKeysGetter from 'trait/has/NamespaceWithKeysGetter'

const descriptor = 'Gaia.Pane'

/**
 * Pane component module.
 */
export default pipe(
  componentComposition(descriptor),
  withGetterSetter(G.ACTIONS),
  withGetterSetter(G.TEMPLATE),
  hasNamespace(G.KEYS),
  hasNamespaceKeysGetter(G.CHILDREN),
  hasNamespaceKeysGetter(G.PROPS),
)
