/* global G */
import _route from 'trait/composition/route'

/**
 * Error handler
 *
 * Redirects to the app's defined error route and dispatches a hashchange event.
 * If a `callback` is provided, it will execute it. The callback is usually used
 * to log specific information about the error that may vary depending on where
 * we call this action from.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @returns {(function(*, function()=): Promise<void>)|*}
 */
const error = obj => async (e, callback = null) => {
  const errorRoute = obj[G.CONFIGURATION].defaults.error
  const route = _route(errorRoute.module, errorRoute.action)

  await obj[G.ADAPTER][G.ROUTER][G.API].moduleAction(route, { error: e, key: e.key })
  obj[G.ADAPTER][G.EVENTS].dispatchEvent(new CustomEvent('gaia:hashchange', {
    bubbles: true,
    cancelable: true,
    detail: {},
  }))

  callback?.()
}

export default error
