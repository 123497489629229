import config from './config'
import component from './component'
import module from './module'
import ui from './ui'
import model from './model'
import modelAttribute from './model/attribute'
import validator from './validator'
import list from './list'
import lazy from './lazy'

const factories = {
  component,
  config,
  module,
  ui,
  model,
  validator,
  list,
  modelAttribute,
  lazy,
}

/**
 * @memberOf Gaia#
 * @namespace SyncFactories
 *
 * @typedef {Object} Gaia.SyncFactories
 * @property {function} config - {@link Gaia.Factory.Config}
 * @property {function} module - {@link Gaia.Factory.Module}
 */
const bundledFactories = {}

/**
 * Factories Initializer
 *
 * It requires providers, in order for factories to expand their sources
 * It requires loaders, in order to provide artifacts for building entities
 *
 * @param {Object} providers - native providers
 * @return {function(*=): Object} Gaia.SyncFactories - {@link Gaia.SyncFactories}
 */
const initFactories = providers => (loader) => {
  Object
    .keys(factories)
    .forEach((key) => {
      const factory = factories[key]
      bundledFactories[key] = providers[key] ? factory(providers[key])(loader) : factory(loader)
    })
  return bundledFactories
}

export default initFactories
