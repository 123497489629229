/* global G */
import { getUrl, noLoaderMiddleware } from '@platform/adapter/message/helper'
import { getFirstItem } from 'lib/util'
import { defaultMiddleware } from 'platform/adapter/http/middleware'

/**
 * Attempts to update a message from the server. On success, it also updates it from the adapter's
 * data and then dispatches a G.DONE event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const item = detail[G.DATA]
  const callback = detail[G.FN]
  const hasAttachments = detail[G.ATTACHMENT]
  const adapter = app[G.ADAPTER][G.MESSAGE]
  const adapterData = adapter[G.DATA]
  const adapterItem = adapterData.find(dataItem => dataItem.key === item.key)

  const editTimestamp = new Date().toISOString()

  const params = {
    ...item,
    _rev: adapterItem._rev,
    value: {
      ...item.value,
      editTimestamp,
    },
  }

  // Initializing the loader if we have attachments
  hasAttachments && eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))

  const url = getUrl(app, { key: item.key })
  const response = await app[G.ADAPTER][G.HTTP][G.API].patch({ url, params }, {
    middleware: hasAttachments
      ? noLoaderMiddleware
      : defaultMiddleware,
  })
  const result = getFirstItem(response)

  if (result.ok) {
    adapterItem._rev = result.rev
    adapterItem.value = {
      ...adapterItem.value,
      ...params.value,
    }

    const done = await callback?.(adapterItem)

    // Closing the loader
    done && hasAttachments && eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

    eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
